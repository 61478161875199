import React, { useState } from 'react'

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faMemo } from '@fortawesome/pro-solid-svg-icons/faMemo'
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'

import { View, Platform } from 'react-native'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { getRestaurantCourseText } from './common'
import { getCustomerDisplayName } from '../Customers/Customer'
import { VisitStatusTag } from '../Restaurant/Reservation/ListView/VisitStatusTag'
import {
  RestaurantReservationStatus,
  updateRestaurantReservation,
  useRestaurantReservation,
} from '../../models/RestaurantReservation'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  RestaurantReservation,
  RestaurantReservationVisitStatus,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { RestaurantCourseResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_resource'
import { Customer as CustomerModel } from '../../models/Customer'
import { Attribute } from '../../models/CustomerAttributes'
import SmartPaymentAvailableButton, {
  RevisionSmartPaymentButton,
} from '@hello-ai/for_r_app/src/components/Reservations/SmartPayment/SmartPaymentAvailableButton'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { Restaurant, useRestaurant } from '../../models/Restaurant'

import { TableSeat, useTableSeatsBulk } from '../../models/TableSeat'
import { getFormatTime } from '../../modules/time'
import { useRestaurantCurrency } from '../../modules/useRestaurantCurrency'
import { displayToastSuccess } from '../Shared/Toast'
import { CustomerOrderHistory } from './CustomerOrderHistory'
import { CustomerOrderHistorySmall } from './CustomerOrderHistorySmall'
import Row from './Row'
import { labelColors } from '../../components/Customers/Attributes'
import { mutate, swrKey } from '../../modules/swr'

const fontSize = 18
const rowMargin = 8

export function ReservationDetails({
  restaurantId,
  date,
  startTime,
  endTime,
  reservationChildDetails,
  adultPartySize,
  childPartySize,
  tableSeatIds,
  restaurantReservationId,
  reservationStatus,
  visitStatus,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  startTime: number
  endTime: number
  adultPartySize: number
  childPartySize: number
  reservationChildDetails: RestaurantReservation['reservation_child_details']
  // TODO: Can't we just pass in the tableSeats array directly?
  tableSeatIds: Array<TableSeat['id']>
  // 予約変更の場合のみ予約ID、予約ステータス、来店ステータスが必要。
  restaurantReservationId?: string
  reservationStatus?: RestaurantReservationStatus
  visitStatus?: RestaurantReservationVisitStatus
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const token = useToken()
  const { tableSeats } = useTableSeatsBulk(restaurantId, {
    ids: tableSeatIds,
  })
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )
  const { data: restaurant } = useRestaurant(restaurantId)

  // 予約変更時、この画面内でステータスを更新したら、画面上も更新する
  const onUpdateVisitStatus = async (
    newVisitStatus: RestaurantReservationVisitStatus | undefined
  ) => {
    if (restaurantReservationId == null) return
    const { error } = await updateRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      {
        visit_status: newVisitStatus,
      }
    )
    if (error !== undefined) return
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations`,
        {
          date: date.format('YYYY-MM-DD'),
        }
      )
    )
    displayToastSuccess(t('来店ステータスを更新しました'))
  }

  const onPressSmartPayment = () => {
    if (restaurantReservationId == null) return
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservationId}/smart_payment`
      )
    } else {
      navigation.navigate('SmartPayment', {
        restaurantReservationId,
      })
    }
  }

  const onPressRevisionSmartPayment = () => {
    if (restaurantReservation?.smart_payment == null) return
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/revision_smart_payment`
      )
    } else {
      navigation.navigate('RevisionSmartPayment', {
        restaurantReservationId: restaurantReservation.id,
      })
    }
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap-reverse',
      }}
    >
      <View style={{ flex: 1 }}>
        <Row icon={faCalendarAlt}>
          <Text style={{ fontWeight: '600' }}>
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            {date.format('YYYY年M月DD日(ddd)')}
          </Text>
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faClock}
        >
          <Text style={{ fontWeight: '600' }}>
            {getFormatTime(startTime)}~{getFormatTime(endTime)}
          </Text>
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faUserFriends}
        >
          {(reservationChildDetails?.length ?? 0) > 0 ? (
            <Text style={{ fontSize, fontWeight: '600', flex: 1 }}>
              {t('{{count}}名様', {
                count: adultPartySize + childPartySize,
              })}
              {`(${t('お子様{{count}}名', {
                count: reservationChildDetails?.length,
              })}：${reservationChildDetails
                ?.map((child) => t('{{count}}歳', { count: child.age }))
                .join('/')}) `}
            </Text>
          ) : (
            <Text style={{ fontSize, fontWeight: '600' }}>
              {t(
                '{{party_size}} ({{adult_party_size}}、{{child_party_size}})',
                {
                  party_size: t('{{count}}名様', {
                    count: adultPartySize + childPartySize,
                  }),
                  adult_party_size: t('大人{{count}}', {
                    count: adultPartySize,
                  }),
                  child_party_size: t('お子様{{count}}', {
                    count: childPartySize,
                  }),
                }
              )}
            </Text>
          )}
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faChair}
        >
          <Text style={{ fontSize }}>
            {(tableSeats &&
              tableSeats.map((tableSeat) => tableSeat.name).join('、')) ||
              t('なし')}
          </Text>
        </Row>
      </View>
      <View
        style={[
          {
            gap: 8,
          },
          width >= sm
            ? { flexDirection: 'row' }
            : {
                alignItems: 'flex-end',
                paddingBottom: 16,
              },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            columnGap: 8,
          }}
        >
          {reservationStatus !== undefined && (
            <VisitStatusTag
              style={{
                height: 36,
              }}
              alignPopover={'right'}
              reservationStatus={reservationStatus!}
              visitStatus={visitStatus ?? 'all_visited'}
              onUpdateVisitStatus={onUpdateVisitStatus}
            />
          )}
          {restaurantReservation != null &&
            restaurant?.table_restaurant_setting?.smart_payment_available && (
              <SmartPaymentAvailableButton
                buttonStyle={{ width: 220, height: 36 }}
                restaurantReservation={restaurantReservation}
                onPressSmartPayment={onPressSmartPayment}
              />
            )}
        </View>
        {restaurant?.table_restaurant_setting?.smart_payment_available &&
          restaurantReservation?.smart_payment?.status === 'paid' && (
            <RevisionSmartPaymentButton
              restaurantId={restaurantId}
              restaurantReservation={restaurantReservation}
            />
          )}
      </View>
    </View>
  )
}

export function Customer({
  customer,
  attributes,
  right,
  restaurant,
}: {
  customer: CustomerModel
  attributes?: Attribute[]
  right?: React.ReactNode
  restaurant?: Pick<Restaurant, 'price_unit'>
}) {
  const { width, sm } = useResponsive()
  const attributeIds =
    customer.profiles?.slice(-1)[0]?.custom_attributes?.split(',') ?? []
  const selectedAttributes = attributes?.filter((i) =>
    attributeIds.includes(i.id)
  )
  const customerNote = customer.restaurant_customer_notes?.reduce(
    (previousValue, note) => previousValue + note.content,
    ''
  )

  const [isOpenPreviousOrder, setIsOpenPreviousOrder] = useState(true)
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Row icon={faAddressCard}>
            <Text
              style={{
                fontWeight: '600',
              }}
            >
              {getCustomerDisplayName(customer)}
            </Text>
          </Row>
          <Row
            style={{
              marginTop: rowMargin,
            }}
            icon={faPhoneAlt}
          >
            <Text
              style={{
                fontWeight: '600',
              }}
            >
              {customer.phone_number_format ?? t('未登録')}
            </Text>
          </Row>
          <Row
            style={{
              marginTop: rowMargin,
            }}
            icon={faEnvelope}
          >
            <Text>{customer.email || t('なし')}</Text>
          </Row>
          <Row
            style={{
              marginTop: rowMargin,
            }}
            icon={faFrown}
          >
            <Text>
              {customer.allergy != null ? customer.allergy : t('未記入')}
            </Text>
          </Row>
          {selectedAttributes != null && selectedAttributes.length !== 0 && (
            <Row
              style={{
                marginTop: rowMargin,
                gap: 8,
              }}
              icon={faTag}
            >
              {selectedAttributes.map((attribute) => (
                <Text
                  key={attribute.id}
                  style={{
                    borderRadius: 2,
                    backgroundColor:
                      labelColors[attribute.label_color].backgroundColor,
                    color: labelColors[attribute.label_color].text,
                    paddingHorizontal: 8,
                    paddingVertical: 3,
                    fontSize: 12,
                    fontWeight: '600',
                  }}
                >
                  {attribute.name}
                </Text>
              ))}
            </Row>
          )}
          {customerNote != null && customerNote.length !== 0 && (
            <Row
              style={{
                marginTop: rowMargin,
                marginRight: 32,
              }}
              icon={faMemo}
            >
              <Text>{customerNote}</Text>
            </Row>
          )}
        </View>
        {right}
      </View>
      {customer.previous_table_order && (
        <View style={{ marginTop: 8 }}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 4,
              paddingVertical: 8,
            }}
            onPress={() => setIsOpenPreviousOrder(!isOpenPreviousOrder)}
          >
            <FontAwesomeIcon
              icon={faHistory}
              size={20}
              color={Colors.black60}
            />
            <Text style={{ fontSize: 18, color: Colors.black }}>
              {t('この顧客の前回の注文')}
            </Text>
            <FontAwesomeIcon
              icon={isOpenPreviousOrder ? faAngleUp : faAngleDown}
              size={20}
              color={Colors.black60}
            />
          </TouchableOpacity>
          {isOpenPreviousOrder && (
            <View
              style={{
                marginTop: 4,
                backgroundColor: Colors.bgBlack,
                padding: 24,
              }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14 }}>
                {dayjs(customer.previous_table_order.started_at).format('ll')}
              </Text>
              {width < sm ? (
                <CustomerOrderHistorySmall
                  tableOrder={customer.previous_table_order}
                  restaurant={restaurant}
                />
              ) : (
                <CustomerOrderHistory
                  tableOrder={customer.previous_table_order}
                  restaurant={restaurant}
                />
              )}
            </View>
          )}
        </View>
      )}
    </>
  )
}

function getTextMarginTop({
  fontSize,
  lineHeight,
}: {
  fontSize: number
  lineHeight: number
}) {
  return -(lineHeight - fontSize) / 2
}

export function ReservationCoursesAndMemo({
  restaurantId,
  reservationCourses,
  allergy,
  memo,
}: {
  restaurantId: number
  reservationCourses: Array<{
    partySize: number
    restaurantCourse:
      | (Pick<RestaurantCourseResource, 'id' | 'title'> & {
          price: number | null
        })
      | undefined
    restaurantCourseQuestionAnswers: Array<{
      text: string | null
      restaurantCourseQuestion: {
        description: string
      }
      restaurantCourseQuestionSelections: Array<{
        description: string
      }>
    }>
  }>
  allergy: string
  memo: string | null
}) {
  const { data: restaurant } = useRestaurant(restaurantId)
  const { currencyWithDelimiter } = useRestaurantCurrency(restaurant)
  return (
    <View>
      <Row mode="inline-expanded" icon={faUtensils}>
        <View style={{ flex: 1 }}>
          {reservationCourses.length === 0 && (
            <Text
              style={{
                fontSize: 18,
                lineHeight: 18 * 1.5,
                marginTop: getTextMarginTop({
                  fontSize: 18,
                  lineHeight: 18 * 1.5,
                }),
              }}
            >
              {t('コースなし')}
            </Text>
          )}
          {reservationCourses.map(
            (
              { partySize, restaurantCourse, restaurantCourseQuestionAnswers },
              index
            ) => (
              <View
                key={`${index}`}
                style={{
                  marginTop: index === 0 ? 0 : 8,
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 18,
                    lineHeight: 18 * 1.5,
                    marginTop: getTextMarginTop({
                      fontSize: 18,
                      lineHeight: 18 * 1.5,
                    }),
                  }}
                >
                  {restaurantCourse == null
                    ? t('コースが見つかりません')
                    : getRestaurantCourseText({
                        title: restaurantCourse.title,
                        price: currencyWithDelimiter(restaurantCourse.price),
                        partySize,
                      })}
                </Text>
                {restaurantCourseQuestionAnswers.map(
                  (restaurantCourseQuestionAnswer, index) => {
                    return (
                      <View
                        key={`${index}`}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.black60,
                              fontWeight: '600',
                            }}
                          >
                            {t('質問')}
                          </Text>
                          <Text
                            style={{
                              marginLeft: 8,
                              flex: 1,
                            }}
                          >
                            {
                              restaurantCourseQuestionAnswer
                                .restaurantCourseQuestion.description
                            }
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: 'row',
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.black60,
                              fontWeight: '600',
                            }}
                          >
                            {t('回答')}
                          </Text>
                          <Text
                            style={{
                              marginLeft: 8,
                              fontWeight: '600',
                            }}
                            selectable
                          >
                            {(restaurantCourseQuestionAnswer.text ?? '') !== ''
                              ? restaurantCourseQuestionAnswer.text
                              : restaurantCourseQuestionAnswer.restaurantCourseQuestionSelections
                                  .map((selection) => selection.description)
                                  .join(', ')}
                          </Text>
                        </View>
                      </View>
                    )
                  }
                )}
              </View>
            )
          )}
        </View>
      </Row>
      <Row
        style={{
          marginTop: 24,
        }}
        icon={faFrown}
      >
        <Text selectable>{allergy || t('未記入')}</Text>
      </Row>
      <Row
        style={{
          marginTop: 24,
        }}
        icon={faEdit}
      >
        <Text selectable>{(memo ?? '') || t('未記入')}</Text>
      </Row>
    </View>
  )
}
