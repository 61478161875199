import React, { useCallback, useMemo, useState } from 'react'
import { View, FlatList, Dimensions, ScrollView } from 'react-native'
import { Tabs, TabProps, TabContent } from 'components/Shared/Navigation/Tab'
import Reservations, {
  DateStateProvider,
} from 'pages/Restaurants/RestaurantReservations'

import { Route, Routes } from 'react-router'
import { Header } from '../../components/Shared/Navigation/Header'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DrawerHeaderToggle } from '../../components/Shared/Navigation/Drawer'
import { Restaurant, useRestaurant } from '../../models/Restaurant'
import { useRestaurantId } from '../../modules/useRestaurantId'
import { Unavailable } from '@hello-ai/for_r_app/src/components/Restaurant/Unavailable'
import config from '@hello-ai/for_r_app/src/modules/config'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { ListView } from '@hello-ai/for_r_app/src/components/Reservations/ListView'
import { ChartView } from '@hello-ai/for_r_app/src/components/Reservations/ChartView'
import { MapView } from '@hello-ai/for_r_app/src/components/Reservations/MapView'
import { useNavigate } from 'modules/router/useNavigate'
import { useSearchParams } from 'react-router-dom'
import {
  ResponsiveProvider,
  useResponsive,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { HeaderSegmentedControl } from '@hello-ai/for_r_app/src/components/Reservations/HeaderSegmentedControl'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { HeaderDateSelectBar } from '@hello-ai/for_r_app/src/components/Reservations/HeaderDateSelectBar'
import { HeaderViewModeSegmentedControl } from '@hello-ai/for_r_app/src/components/Reservations/HeaderViewModeButton'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import {
  markAsReadRestaurantReservationActivityNotification,
  useRestaurantUnreadReservationActivityNotifications,
} from '@hello-ai/for_r_app/src/models/RestaurantReservation'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { HeaderRightButton } from '@hello-ai/for_r_app/src/components/Shared/HeaderRightButton'
import HeaderRightDailyNoteIconButton from '@hello-ai/for_r_app/src/components/Reservations/HeaderRightDailyNoteIconButton'
import DatePicker from 'components/Shared/DatePicker'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { getCustomerDisplayName } from '@hello-ai/for_r_app/src/components/Customers/Customer'

function HeaderTitle({
  date,
  onChangeDate,
}: {
  date: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
}) {
  return (
    <DatePicker
      triggerElement={({ onPress }) => (
        <TouchableOpacity
          style={{
            backgroundColor: Colors.primaryBg,
            paddingVertical: 10,
            paddingHorizontal: 16,
            borderRadius: 24,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
          }}
          onPress={onPress}
        >
          <TouchableOpacity
            onPress={() => onChangeDate(date.subtract(1, 'day'))}
            onPressMinInterval={0}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              size={18}
              color={Colors.primary}
            />
          </TouchableOpacity>
          <Text
            style={{
              marginHorizontal: 24,
              fontWeight: '600',
              fontSize: 18,
              color: Colors.primary,
            }}
          >
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            {date.format('YYYY年M月DD日(ddd)')}
          </Text>
          <TouchableOpacity
            onPress={() => onChangeDate(date.add(1, 'day'))}
            onPressMinInterval={0}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              size={18}
              color={Colors.primary}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      )}
      value={date}
      onChange={(date) => {
        if (date == null) return
        onChangeDate(dayjs(date.toDate()))
      }}
    />
  )
}

export function ReservationBookUnavailable({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  const arWebOrigin = config.isStaging
    ? 'https://staging.autoreserve.com'
    : 'https://autoreserve.com'
  return (
    <>
      <Header
        title={t('予約')}
        headerLeft={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DrawerHeaderToggle />
            <View
              style={{
                marginLeft: 24,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  height: 24,
                  paddingHorizontal: 8,
                  backgroundColor: Colors.accentBg,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: Colors.accent,
                    fontWeight: '600',
                  }}
                >
                  {restaurant?.table_restaurant_setting?.web_reservation_enabled
                    ? t('ネット予約')
                    : t('予約代行')}
                </Text>
              </View>
            </View>
          </View>
        }
      />
      <Unavailable
        description={t(
          '予約管理も集客もカンタンにできる予約台帳を利用しませんか？'
        )}
        toService={`${arWebOrigin}/for_restaurants/reservation_book`}
        toContact={`${arWebOrigin}/for_restaurants/contact`}
      />
    </>
  )
}

export function ReservationsStandardOrHigher({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { width, sm, md } = useResponsive()
  const [isOpenPop, setIsOpenPop] = useState(false)
  const [currentNotificationPage, setCurrentNotificationPage] = useState(1)
  const { width: dimensionWidth } = Dimensions.get('window')
  const date =
    params.get('date') != null
      ? dayjs(params.get('date'), 'YYYY-MM-DD')
      : dayjs()
  const startTimeParam = params.get('startTime')
  const startTime =
    startTimeParam != null ? parseInt(startTimeParam) ?? undefined : undefined
  const modeParam = params.get('m')
  const currentDisplayMode = useMemo(
    () =>
      modeParam === 'chart' ? 'chart' : modeParam === 'map' ? 'map' : 'list',
    [modeParam]
  )

  const { data, mutate } = useRestaurantUnreadReservationActivityNotifications(
    restaurant.id,
    currentNotificationPage
  )

  function HorizontalDivider() {
    return (
      <View
        style={{
          height: 24,
          width: 1,
          backgroundColor: Colors.border,
        }}
      />
    )
  }

  const renderNotificationIcon = (
    message_type: NonNullable<
      ReturnType<
        typeof useRestaurantUnreadReservationActivityNotifications
      >['data']
    >['data'][number]['message_type']
  ) => {
    let icon = null
    let color = null

    switch (message_type) {
      case 'canceled':
        icon = faTimesCircle
        color = Colors.caution
        break
      case 'modified':
        icon = faExchange
        color = Colors.black60
        break
      case 'requested':
      case 'modification_requested':
        icon = faExclamationCircle
        color = Colors.primary
        break
      case 'reserved':
        icon = faCheckCircle
        color = Colors.accent
        break
    }
    return <FontAwesomeIcon icon={icon} color={color} size={20} />
  }

  const onChangeDate = useCallback(
    (date: dayjs.Dayjs) => {
      navigate(
        `/restaurants/${restaurant.id}/reservations?date=${date.format(
          'YYYY-MM-DD'
        )}&m=${currentDisplayMode}`
      )
    },
    [navigate, restaurant.id, currentDisplayMode]
  )

  return (
    <DateStateProvider value={[date, onChangeDate]}>
      <Header
        title={
          width < sm && (
            <HeaderSegmentedControl
              viewMode={currentDisplayMode}
              onPressChart={() => {
                navigate(
                  `/restaurants/${
                    restaurant.id
                  }/reservations?date=${date.format('YYYY-MM-DD')}&m=chart`
                )
              }}
              onPressList={() => {
                navigate(
                  `/restaurants/${
                    restaurant.id
                  }/reservations?date=${date.format('YYYY-MM-DD')}&m=list`
                )
              }}
            />
          )
        }
        headerLeft={
          <View
            style={{
              marginLeft: width > sm ? 24 : 16,
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {width < md && <DrawerHeaderToggle />}
            {width >= sm && (
              <>
                {currentDisplayMode === 'map' ? (
                  <Text
                    style={{
                      flex: 1,
                      textAlign: 'right',
                      fontWeight: '600',
                      fontSize: 18,
                      color: Colors.primary,
                    }}
                  >
                    {width < sm
                      ? dayjs().format('YYYY/M/D(ddd)')
                      : // eslint-disable-next-line ar-i18n/require-translation-ja
                        dayjs().format('YYYY年M月D日(ddd)')}
                  </Text>
                ) : (
                  <>
                    <HeaderDateSelectBar
                      restaurantId={restaurant.id}
                      date={date}
                      onChangeDate={onChangeDate}
                    />
                    <View
                      style={{
                        marginLeft: 24,
                        width: 1,
                        height: '100%',
                        backgroundColor: Colors.border,
                      }}
                    />
                    <Button
                      mode="outline"
                      style={{
                        marginLeft: 24,
                        width: 120,
                        paddingVertical: 6,
                        paddingHorizontal: 16,
                        marginRight: 8,
                      }}
                      textStyle={{
                        fontSize: 14,
                        fontWeight: '300',
                        lineHeight: 24,
                      }}
                      onPress={() => {
                        onChangeDate(dayjs().tz())
                      }}
                    >
                      {t('今日を表示')}
                    </Button>
                  </>
                )}
              </>
            )}
          </View>
        }
        headerRight={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 12,
              marginRight: width > sm ? 24 : 16,
            }}
          >
            {width >= sm && (
              <HeaderViewModeSegmentedControl
                viewMode={currentDisplayMode}
                onPress={(value) => {
                  navigate(
                    `/restaurants/${restaurant.id}/reservations?date=${date.format('YYYY-MM-DD')}&m=${value}`
                  )
                }}
              />
            )}
            {width >= sm && <HorizontalDivider />}
            <HeaderRightDailyNoteIconButton
              restaurantId={restaurant.id}
              date={date}
            />
            {width >= sm && <HorizontalDivider />}
            <Popover
              isOpen={isOpenPop}
              placement="bottomEnd"
              anchor={
                <HeaderRightButton
                  style={{ paddingRight: 0 }}
                  badgeCount={data?.headerData?.totalCount}
                  icon={
                    <FontAwesomeIcon
                      icon={faBell}
                      size={width < sm ? 20 : 32}
                      color={Colors.primary}
                    />
                  }
                  onPress={() => setIsOpenPop(!isOpenPop)}
                />
              }
              onClose={() => setIsOpenPop(false)}
              showArrowElement={width >= sm}
            >
              <ResponsiveProvider>
                <View
                  style={{
                    width: dimensionWidth - 32,
                    maxWidth: 400,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingHorizontal: 16,
                      paddingVertical: 24,
                      borderBottomWidth: 1,
                      borderBottomColor: Colors.border,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 16,
                      }}
                    >
                      <Text style={{ fontWeight: '600', fontSize: 22 }}>
                        {t('予約お知らせ')}
                      </Text>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 2,
                        }}
                        disabled={data?.data?.length === 0}
                        onPress={() => {
                          markAsReadRestaurantReservationActivityNotification(
                            restaurant.id,
                            {
                              reservation_activity_notification: {
                                mark_all_as_read: true,
                              },
                            }
                          ).then(() => {
                            mutate()
                          })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          size={16}
                          color={Colors.primary}
                        />
                        <Text style={{ color: Colors.primary, fontSize: 14 }}>
                          {t('すべて既読にする')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity onPress={() => setIsOpenPop(false)}>
                      <FontAwesomeIcon
                        icon={faClose}
                        size={24}
                        color={Colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                  <ScrollView style={{ maxHeight: 480 }}>
                    <FlatList
                      data={data?.data}
                      ListEmptyComponent={() => (
                        <View
                          style={{
                            height: 120,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              fontWeight: '600',
                              color: Colors.black60,
                            }}
                          >
                            {t('予約のお知らせはありません')}
                          </Text>
                        </View>
                      )}
                      renderItem={({ item }) => (
                        <TouchableOpacity
                          style={{
                            padding: 16,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          onPress={async () => {
                            markAsReadRestaurantReservationActivityNotification(
                              restaurant.id,
                              {
                                reservation_activity_notification: {
                                  uuids: [item.id],
                                },
                              }
                            )
                            mutate()
                            setIsOpenPop(false)
                            switch (item.notifiable_type) {
                              case 'Reservation':
                                navigate(
                                  `/restaurants/${restaurant.id}/reservations/${item.notifiable.id}`
                                )
                                break
                              case 'ReservationElement': {
                                const startAt = dayjs(item.notifiable.start_at)
                                navigate(
                                  `/restaurants/${
                                    restaurant.id
                                  }/reservations?date=${startAt.format(
                                    'YYYY-MM-DD'
                                  )}&startTime=${
                                    startAt.hour() * 3600 +
                                    startAt.minute() * 60
                                  }&m=chart`
                                )
                                break
                              }
                            }
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 24,
                              flex: 1,
                            }}
                          >
                            <View
                              style={{
                                width: 6,
                                height: 6,
                                borderRadius: 3,
                                backgroundColor:
                                  item.status === 'unread'
                                    ? Colors.accent
                                    : 'transparent',
                              }}
                            />
                            <View style={{ flex: 1, gap: 16 }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 4,
                                }}
                              >
                                {renderNotificationIcon(item.message_type)}
                                <Text
                                  style={{
                                    fontWeight: '600',
                                    fontSize: 18,
                                    color: Colors.black,
                                  }}
                                >
                                  {item.title}
                                </Text>
                              </View>
                              <View style={{ gap: 8 }}>
                                {item.source !== 'autoreserve' && (
                                  <View style={{ flexDirection: 'row' }}>
                                    <Text
                                      style={[
                                        {
                                          fontSize: 10,
                                          fontWeight: '600',
                                          lineHeight: 10,
                                          textAlign: 'center',
                                          paddingVertical: 2,
                                          paddingHorizontal: 8,
                                          borderRadius: 2,
                                          overflow: 'hidden',
                                        },
                                        GOURMET_SITE_PROVIDER_TEXT_STYLE[
                                          item.source
                                        ],
                                      ]}
                                    >
                                      {
                                        GOURMET_SITE_PROVIDER_SOURCE[
                                          item.source
                                        ].label
                                      }
                                    </Text>
                                  </View>
                                )}
                                <Text
                                  style={{
                                    fontSize: 14,
                                    color: Colors.black60,
                                    lineHeight: 14,
                                  }}
                                >
                                  {t('来店日：{{date}}', {
                                    date: dayjs(
                                      item.notifiable.start_at
                                    ).format('ll'),
                                  })}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    color: Colors.black60,
                                    lineHeight: 14,
                                  }}
                                >
                                  {t('来店時刻：{{time}}', {
                                    time: dayjs(
                                      item.notifiable.start_at
                                    ).format('LT'),
                                  })}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    color: Colors.black60,
                                    lineHeight: 14,
                                  }}
                                >
                                  {t(
                                    '予約者：{{name}}様（合計：{{count}}名）',
                                    {
                                      name:
                                        item.notifiable_type ===
                                        'ReservationElement'
                                          ? getCustomerDisplayName(
                                              item.notifiable.customer
                                            )
                                          : getCustomerDisplayName(
                                              item.notifiable.customers[0]
                                            ),
                                      count: item.notifiable.party_size,
                                    }
                                  )}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            size={16}
                            color={Colors.black80}
                          />
                        </TouchableOpacity>
                      )}
                    />
                  </ScrollView>
                  <View
                    style={{
                      padding: 16,
                      borderTopWidth: 1,
                      borderTopColor: Colors.border,
                    }}
                  >
                    <Pagination
                      currentPage={currentNotificationPage}
                      totalPage={data?.headerData?.totalPages ?? 0}
                      setPage={(page) => setCurrentNotificationPage(page)}
                    />
                  </View>
                </View>
              </ResponsiveProvider>
            </Popover>
          </View>
        }
      />
      {width <= sm && (
        <View
          style={{
            paddingVertical: 8,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
          }}
        >
          <HeaderDateSelectBar
            restaurantId={restaurant.id}
            date={date}
            onChangeDate={onChangeDate}
          />
        </View>
      )}
      {currentDisplayMode === 'list' ? (
        <ListView
          restaurantId={restaurant.id}
          date={date}
          webReservationType={
            restaurant?.table_restaurant_setting?.web_reservation_type ??
            'normal'
          }
          dateString={date.format('YYYY-MM-DD')}
          onPressRequestRestaurantReservation={(requestReservation) => {
            if (requestReservation.reservation?.reservation_change_request) {
              navigate(
                `/restaurants/${restaurant.id}/reservations/change_requests/${requestReservation.reservation.uuid}`
              )
            } else {
              navigate(
                `/restaurants/${restaurant.id}/reservations/requests/${requestReservation.id}`
              )
            }
          }}
          onPressRestaurantReservation={(id) =>
            navigate(`/restaurants/${restaurant.id}/reservations/${id}`)
          }
          onPressCustomer={(id) => {
            navigate(`/restaurants/${restaurant.id}/customers/${id}`)
          }}
          onPressAddReservationButton={() =>
            navigate(
              `/restaurants/${
                restaurant.id
              }/reservations/new?dateString=${date.format('YYYY-MM-DD')}`
            )
          }
        />
      ) : currentDisplayMode === 'map' ? (
        <MapView restaurantId={restaurant.id} />
      ) : (
        <ChartView
          restaurantId={restaurant.id}
          startTime={startTime}
          date={date}
          dateString={date.format('YYYY-MM-DD')}
          onPressAddReservationButton={() =>
            navigate(
              `/restaurants/${
                restaurant.id
              }/reservations/new?dateString=${date.format('YYYY-MM-DD')}`
            )
          }
        />
      )}
    </DateStateProvider>
  )
}

export default function ReservationsTab() {
  const restaurantId = useRestaurantId()
  const [date, setDate] = useState(() => dayjs())
  const dateState = useMemo<[dayjs.Dayjs, (date: dayjs.Dayjs) => void]>(
    () => [date, setDate],
    [date]
  )

  const { restaurant } = useRestaurant(restaurantId)
  const ReservationTabs: TabProps[] = useMemo(() => {
    switch (restaurant?.table_restaurant_setting?.web_reservation_type) {
      case 'normal':
        return [
          {
            title: t('成立'),
            fullPath: '/restaurants/:id/reservations',
            relativePath: '/',
            element: <Reservations type="reserved" />,
          },
          {
            title: t('キャンセル'),
            fullPath: '/restaurants/:id/reservations/canceled',
            relativePath: '/canceled',
            element: <Reservations type="canceled" />,
          },
        ]
      case 'request':
        return [
          {
            title: t('成立'),
            fullPath: '/restaurants/:id/reservations',
            relativePath: '/',
            element: <Reservations type="reserved" />,
          },
          {
            title: t('リクエスト'),
            fullPath: '/restaurants/:id/reservations/request',
            relativePath: '/request',
            element: <Reservations type="request" />,
          },
          {
            title: t('キャンセル'),
            fullPath: '/restaurants/:id/reservations/canceled',
            relativePath: '/canceled',
            element: <Reservations type="canceled" />,
          },
        ]
      default:
        return []
    }
  }, [restaurant?.table_restaurant_setting?.web_reservation_type])

  return (
    <DateStateProvider value={dateState}>
      <View style={{ flex: 1 }}>
        <Header
          title={<HeaderTitle date={date} onChangeDate={setDate} />}
          headerLeft={
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DrawerHeaderToggle />
              <View
                style={{
                  marginLeft: 24,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    height: 24,
                    paddingHorizontal: 8,
                    backgroundColor: Colors.accentBg,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.accent,
                      fontWeight: '600',
                    }}
                  >
                    {restaurant?.table_restaurant_setting
                      ?.web_reservation_enabled
                      ? t('ネット予約')
                      : t('予約代行')}
                  </Text>
                </View>
              </View>
            </View>
          }
        />
        <Tabs tabs={ReservationTabs} />
        <TabContent>
          <Routes>
            {ReservationTabs.map((tab) => {
              return (
                <Route
                  key={tab.fullPath}
                  path={tab.relativePath}
                  element={tab.element}
                />
              )
            })}
          </Routes>
        </TabContent>
      </View>
    </DateStateProvider>
  )
}
