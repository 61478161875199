import { faArrowsAltH } from '@fortawesome/pro-solid-svg-icons/faArrowsAltH'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { inRange, range, times } from 'lodash'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Alert,
  FlatList,
  FlexStyle,
  Platform,
  ScrollViewProps,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

import { useRestaurantBusinessTimesByDate } from '../../../models/RestaurantBusinessTime'
import {
  RestaurantReservation as RestaurantReservationModel,
  exchangeRestaurantReservationTableSeats,
  isSiteControllerReservation,
  updateRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import Loading from '../../Shared/Loading'

import { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import {
  Gesture,
  GestureDetector,
  ScrollView,
} from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { snapPoint } from 'react-native-redash'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import {
  getFormatTime,
  toHoursAndMinutes,
  toSeconds,
} from '../../../modules/time'
import { getCustomerDisplayName } from '../../Customers/Customer'

import { faArrowsUpDown } from '@fortawesome/pro-solid-svg-icons/faArrowsUpDown'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { faRepeat } from '@fortawesome/pro-solid-svg-icons/faRepeat'
import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { Image } from 'expo-image'
import { Trans } from 'react-i18next'
import { Menu } from 'react-native-paper'
import { useRestaurantRequestReservations } from '../../../models/RestaurantRequestReservation'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { calculateVisitStatusComponentProps } from '../../Restaurant/Reservation/ListView/VisitStatusTag'
import { displayToastSuccess } from '../../Shared/Toast'

export type ChartMode = 'default' | 'selectSeats' | 'selectBlock' | 'seatChange'

export interface SelectParams {
  restaurantReservation: RestaurantReservationModel | undefined
  startTime: number
  endTime: number
  setEndTime: (value: number) => void
  partySize: number
  adultPartySize?: number
  childPartySize?: number
  selectedSeatIds?: Array<TableSeatModel['id']>
  onChangeSelectedSeatIds: (value: Array<TableSeatModel['id']>) => void
}

const verticalDashLineImageUri =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAZAAQMAAABpOAE6AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMVAEEmKywAAAAmSURBVEjH7cmhAQAACMMw/r+K0zBg0JNpZerq98Y555xzzjnnKR/oOlgf2CQXnAAAAABJRU5ErkJggg=='

const scrollViewPaddingY = 24
const scrollViewPcPaddingX = 16
const paddingHorizontal = 11
const cellHeight = 24
const seatPcWidth = 102
const seatSpWidth = 90
const seatPcHeight = 53
const seatSpHeight = 58
const partySizeWidth = 55
const hourPcWidth = 131
const hourSpWidth = 112
const borderWidth = 1

const refreshInterval = 10000

const hours = times(30, (i) => {
  return i
})

const cellPadding = 3

function getCellWidth(hours: number, isSp: boolean) {
  return (isSp ? hourSpWidth : hourPcWidth) * hours - cellPadding * 2
}

function getCellHeight(isSp: boolean) {
  return (isSp ? seatSpHeight : seatPcHeight) - cellPadding * 2
}

const getCellX = (x: number, isSp: boolean) => {
  return (isSp ? hourSpWidth : hourPcWidth) * x + cellPadding
}
const getCellY = (y: number, isSp: boolean) => {
  return cellHeight + (isSp ? seatSpHeight : seatPcHeight) * y + cellPadding
}

function getCellStyle({
  x,
  y,
  isSp,
}: {
  x: number
  y: number
  isSp: boolean
}): ViewStyle {
  return {
    transform: [
      { translateX: getCellX(x, isSp) },
      {
        translateY: getCellY(y, isSp),
      },
    ],
  }
}

const HeaderCell = React.memo(
  ({
    text,
    width,
    isFirst,
    justifyContent = 'flex-start',
    isSp = false,
  }: {
    text: string
    width: number
    isFirst: boolean
    justifyContent?: FlexStyle['justifyContent']
    isSp?: boolean
  }) => {
    return (
      <View
        style={[
          {
            width,
            paddingHorizontal: isSp ? 0 : paddingHorizontal,
            height: cellHeight,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent,
            backgroundColor: isSp ? 'transparent' : '#8f8f8f',
            borderRightWidth: isSp ? 0 : borderWidth,
            borderColor: Colors.border,
            borderTopWidth: isSp ? 0 : borderWidth,
            borderBottomWidth: isSp ? 0 : borderWidth,
            borderLeftWidth: isFirst && !isSp ? borderWidth : 0,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: isSp ? '300' : '600',
            color: isSp ? Colors.black : Colors.white,
          }}
        >
          {text}
        </Text>
      </View>
    )
  }
)

const VerticalDashLine = React.memo(function VerticalDashLine() {
  return (
    <Image
      style={{ width: 1, height: '100%' }}
      source={{
        uri: verticalDashLineImageUri,
      }}
    />
  )
})

function getReservationText(
  restaurantReservation: Pick<RestaurantReservationModel, 'customers' | 'kind'>
) {
  const customers = restaurantReservation.customers

  return customers.length === 0
    ? restaurantReservation.kind === 'walkin'
      ? t('ウォークイン')
      : t('未設定')
    : customers
        .map((customer) =>
          t('{{name}}様', { name: getCustomerDisplayName(customer) })
        )
        .join('、')
}

function Reservation({
  onPress,
  type = 'default',
  index,
  restaurantReservation,
  onDragEnd,
  date,
  isWarning = false,
}: {
  onPress: ({
    absoluteX,
    absoluteY,
  }: {
    absoluteX: number
    absoluteY: number
  }) => void
  type: 'default' | 'unselectable'
  index: number
  date: dayjs.Dayjs
  restaurantReservation: Omit<
    RestaurantReservationModel,
    | 'id'
    | 'status'
    | 'cancel_fee'
    | 'reservation_courses'
    | 'table_seats'
    | 'restaurant_crew_member'
    | 'reservation'
  >
  onDragEnd?: (offsetY: number) => void
  isWarning?: boolean
}) {
  const { width, sm } = useResponsive()
  const startAt = dayjs(restaurantReservation.start_at)
  const dayDiff = startAt.diff(date, 'day')
  const startTime = toSeconds(dayDiff * 24 + startAt.hour(), startAt.minute())
  const endAt = dayjs(restaurantReservation.end_at)

  const hours = endAt.diff(startAt, 'minute') / 60
  const text = getReservationText(restaurantReservation)
  const height = getCellHeight(width < sm)

  // 25時などの場合は 1day ずれる
  const x = getCellX(
    dayDiff * 24 + startAt.hour() + startAt.minute() / 60,
    width < sm
  )
  const startY = useSharedValue(getCellY(index, width < sm))
  const offsetY = useSharedValue(getCellY(index, width < sm))

  const pressGesture = Gesture.Tap()
    .runOnJS(true)
    .onEnd((e, success) => {
      if (success) {
        onPress(e)
      }
    })
  pressGesture.enabled(type !== 'unselectable')
  const isDragging = useSharedValue(false)
  const panGesture = Gesture.Pan()
    //  予約チップのrerender（再フェッチなど）とGestureのactive状態が同時に発生すると画面が固まるため、jsスレッド上で動かす
    .runOnJS(true)
    .enabled(type === 'default')
    .activateAfterLongPress(500)
    .onStart(() => {
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      isDragging.value = true
    })
    .onUpdate((e) => {
      if (!isDragging.value) return
      offsetY.value = e.translationY + startY.value
    })
    .onEnd(() => {
      startY.value = offsetY.value
      if (onDragEnd) {
        const nextIndex = Math.round(
          (offsetY.value - cellHeight - cellPadding) /
            (width < sm ? seatSpHeight : seatPcHeight)
        )
        onDragEnd(nextIndex)
        // NOTE: 予約が被った際にAlertが表示されるとsuccess=falseになり LongPress().onEndでリセットされないケースがあるため、ここでもリセットしておく
        isDragging.value = false
      }
    })

  const dragGesture = Gesture.Simultaneous(panGesture)
  const gestures = Gesture.Race(pressGesture, dragGesture)

  const dragAnimatedStyle = useAnimatedStyle((): ViewStyle => {
    return {
      transform: [{ translateX: x }, { translateY: offsetY.value }],
    }
  }, [x])
  const draggingOpacityStyle = useAnimatedStyle(() => ({
    opacity: isDragging.value ? 0.5 : 1,
  }))
  const draggingIconStyle = useAnimatedStyle(() => ({
    display: isDragging.value ? 'flex' : 'none',
  }))
  const draggingFrontStyle = useAnimatedStyle(() => ({
    zIndex: isDragging.value ? 10 : 5,
  }))

  const { backgroundColor, textColor } = calculateVisitStatusComponentProps(
    restaurantReservation.visit_status ?? 'all_visited'
  )

  const isReservedByAR =
    restaurantReservation.source === 'autoreserve' &&
    restaurantReservation.source_by === 'auto'

  const prevIndex = usePrevious(index)
  // index変更時は位置を更新する
  useEffect(() => {
    if (prevIndex !== index) {
      offsetY.value = getCellY(index, width < sm)
    }
  }, [prevIndex, index, offsetY, sm, width])

  return (
    <GestureDetector gesture={gestures}>
      <Animated.View
        style={[
          {
            backgroundColor,
            position: 'absolute',
            borderRadius: 4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            width: getCellWidth(hours, width < sm),
            height,
          },
          dragAnimatedStyle,
          draggingFrontStyle,
          isWarning && {
            opacity: 0.4,
          },
        ]}
      >
        <View
          style={{
            top: 2,
            right: 2,
            position: 'absolute',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          {isWarning && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size={16}
              color={Colors.caution}
            />
          )}
          {restaurantReservation.has_previous_table_order && (
            <FontAwesomeIcon icon={faHistory} size={16} color={Colors.white} />
          )}
        </View>
        <Animated.View
          style={[
            {
              flexDirection: width < sm ? 'column-reverse' : 'column',
              justifyContent: 'center',
              position: 'relative',
            },
            draggingOpacityStyle,
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text
              style={{ color: textColor, fontSize: 12, lineHeight: 18 }}
              numberOfLines={1}
            >
              {width >= sm && `${getFormatTime(startTime)} - `}
              {t('{{party_size}}({{adult_party_size}},{{child_party_size}})', {
                party_size: t('{{count}}人', {
                  count: restaurantReservation.party_size,
                }),
                adult_party_size: t('大人{{count}}', {
                  count: restaurantReservation.adult_party_size,
                }),
                child_party_size: t('子供{{count}}', {
                  count: restaurantReservation.child_party_size,
                }),
              })}
            </Text>
            {restaurantReservation.smart_payment != null && (
              <View
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: Colors.primary,
                  borderColor: Colors.white,
                  borderWidth: 1,
                  borderRadius: 16,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    lineHeight: 14,
                    fontWeight: '600',
                    color: Colors.white,
                  }}
                >
                  {t('ス')}
                </Text>
              </View>
            )}
          </View>
          <View
            style={[
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            ]}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                color: textColor,
              }}
              numberOfLines={1}
            >
              {text}
            </Text>

            <View
              style={[
                {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: 2,
                },
              ]}
            >
              {!!restaurantReservation.memo && (
                <FontAwesomeIcon
                  style={{ marginTop: 2 }}
                  icon={faPenToSquare}
                  size={16}
                  color={'white'}
                />
              )}
              {isSiteControllerReservation(restaurantReservation) && (
                <View
                  style={{
                    backgroundColor: '#FAF9F5',
                    borderRadius: 8,
                    paddingHorizontal: 6,
                    padding: 2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      lineHeight: 18,
                      fontWeight: '300',
                      color: Colors.primary,
                    }}
                  >
                    {
                      GOURMET_SITE_PROVIDER_SOURCE[
                        restaurantReservation
                          .reservation_site_controller_parsed_course
                          .site_controller_parsed_course.site
                      ].label
                    }
                  </Text>
                </View>
              )}
              {isReservedByAR && (
                <Image
                  style={{
                    height: 16,
                    width: 16,
                    marginTop: 2,
                  }}
                  source={require('../../../assets/images/ar_icon.png')}
                />
              )}
            </View>
          </View>
        </Animated.View>
        <Animated.View
          style={[
            {
              width: 32,
              height: 32,
              borderRadius: 32,
              position: 'absolute',
              top: (height - 32) / 2,
              right: -16,
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: backgroundColor,
              borderWidth: 2,
              backgroundColor: 'white',
            },
            draggingIconStyle,
          ]}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} color={backgroundColor} />
        </Animated.View>
      </Animated.View>
    </GestureDetector>
  )
}

const RestaurantReservationBlockPeriod = React.memo(
  ({
    onPress,
    type = 'default',
    index,
    restaurantReservationBlockPeriod,
    isSp = false,
  }: {
    onPress: ({
      absoluteX,
      absoluteY,
    }: {
      absoluteX: number
      absoluteY: number
    }) => void
    type: 'default' | 'unselectable'
    index: number
    restaurantReservationBlockPeriod: ListPeriodResponse_RestaurantReservationBlockPeriod
    isSp?: boolean
  }) => {
    const startAt = dayjs(
      Timestamp.toDate(restaurantReservationBlockPeriod.startAt!)
    )
    const endAt = dayjs(
      Timestamp.toDate(restaurantReservationBlockPeriod.endAt!)
    )

    const hours = endAt.diff(startAt, 'minute') / 60

    const pressGesture = Gesture.Tap()
      .runOnJS(true)
      .onEnd((e, success) => {
        if (success) {
          onPress(e)
        }
      })
    pressGesture.enabled(type !== 'unselectable')
    const gestures = Gesture.Race(pressGesture)

    return (
      <GestureDetector gesture={gestures}>
        <View
          style={[
            {
              backgroundColor: Colors.black50,
              position: 'absolute',
              borderRadius: 4,
              paddingVertical: 4,
              paddingHorizontal: 8,
              flexDirection: 'column',
              justifyContent: isSp ? 'flex-start' : 'center',
              width: getCellWidth(hours, isSp),
              height: getCellHeight(isSp),
            },
            getCellStyle({
              x: startAt.hour() + startAt.minute() / 60,
              y: index,
              isSp,
            }),
          ]}
        >
          {!isSp && (
            <View>
              <Text style={{ color: 'white', fontSize: 12 }} numberOfLines={1}>
                {startAt.format('H:mm')}-
              </Text>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                color: 'white',
                marginTop: 2,
                fontSize: 14,
              }}
              numberOfLines={1}
            >
              {t('ブロック')}
            </Text>
            {restaurantReservationBlockPeriod.isRepeat && (
              <FontAwesomeIcon
                icon={faRepeat}
                size={14}
                color={'white'}
                style={{ marginLeft: 2 }}
              />
            )}
          </View>
        </View>
      </GestureDetector>
    )
  }
)

const HourCellList = React.memo(function HourCellList({
  otherReservations,
  requestReservations,
  tableSeats,
  isRestaurantOpenAt,
  isSp,
}: {
  otherReservations: RestaurantReservationModel[]
  requestReservations: RestaurantRequestReservation[]
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats']
  isRestaurantOpenAt: (hour: number, minute: number) => boolean
  isSp: boolean
}) {
  const data = React.useMemo(
    () =>
      hours.map((hour, hourIndex) => {
        const hourCells: {
          key: string
          hour: number
          reservationIndex: number
          isFirst: boolean
          borderBottomWidth: number
          type: 'other' | 'request' | 'seat'
        }[] = []

        otherReservations.forEach((_, reservationIndex) => {
          hourCells.push({
            key: `other-${hour}-${reservationIndex}`,
            hour,
            reservationIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              reservationIndex === otherReservations.length - 1
                ? borderWidth * 2
                : borderWidth,
            type: 'other',
          })
        })

        requestReservations.forEach((_, reservationIndex) => {
          hourCells.push({
            key: `request-${hour}-${reservationIndex}`,
            hour,
            reservationIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              reservationIndex === requestReservations.length - 1
                ? borderWidth * 2
                : borderWidth,
            type: 'request',
          })
        })

        tableSeats.forEach((_, seatIndex) => {
          hourCells.push({
            key: `seat-${hour}-${seatIndex}`,
            hour,
            reservationIndex:
              otherReservations.length + requestReservations.length + seatIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              isSp && seatIndex === tableSeats.length - 1 ? 0 : borderWidth,
            type: 'seat',
          })
        })

        return { key: hour, data: hourCells }
      }),
    [otherReservations, requestReservations, tableSeats, isSp]
  )

  const renderItem = React.useCallback<
    (arg: { item: (typeof data)[0] }) => React.ReactElement
  >(
    ({ item }) => {
      return (
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          {item.data.map((item) => {
            return (
              <HourCell
                key={item.key}
                isFirst={item.isFirst}
                borderBottomWidth={item.borderBottomWidth}
                isSp={isSp}
                isOpenAt0min={isRestaurantOpenAt(item.hour, 0)}
                isOpenAt15min={isRestaurantOpenAt(item.hour, 15)}
                isOpenAt30min={isRestaurantOpenAt(item.hour, 30)}
                isOpenAt45min={isRestaurantOpenAt(item.hour, 45)}
              />
            )
          })}
        </View>
      )
    },
    [isSp, isRestaurantOpenAt]
  )
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => `${item.key}`}
      horizontal
    />
  )
})

const HourCell = React.memo(function HourCell({
  isFirst,
  borderBottomWidth = borderWidth,
  isSp = false,
  isOpenAt0min,
  isOpenAt15min,
  isOpenAt30min,
  isOpenAt45min,
}: {
  isFirst: boolean
  borderBottomWidth?: number
  isSp?: boolean
  isOpenAt0min: boolean
  isOpenAt15min: boolean
  isOpenAt30min: boolean
  isOpenAt45min: boolean
}) {
  return (
    <View
      style={[
        {
          width: isSp ? hourSpWidth : hourPcWidth,
          height: isSp ? seatSpHeight : seatPcHeight,
          borderColor: Colors.border,
          borderRightWidth: borderWidth,
          borderBottomWidth,
          borderLeftWidth: isFirst && !isSp ? borderWidth : 0,
          flexDirection: 'row',
          backgroundColor: Colors.white,
        },
      ]}
    >
      <MinuteCell isOpen={isOpenAt0min} />
      <MinuteCell isOpen={isOpenAt15min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt30min} />
      <MinuteCell isOpen={isOpenAt45min} />
    </View>
  )
})

const MinuteCell = React.memo(function MinuteCell({
  isOpen,
}: {
  isOpen: boolean
}) {
  return (
    <View
      style={{
        backgroundColor: isOpen ? 'transparent' : Colors.bgBlack,
        width: '25%',
        height: '100%',
      }}
    />
  )
})

type SelectSeatState =
  | 'over-capacity'
  | 'selectable'
  | 'selected'
  | 'under-capacity'
  | 'unselectable'

function getSelectSeatState({
  selected,
  unselectable,
  partySize,
  minPartySize,
  maxPartySize,
}: {
  selected: boolean
  unselectable: boolean
  partySize: number
  minPartySize?: number
  maxPartySize?: number
}): SelectSeatState {
  if (selected) return 'selected'
  if (unselectable) return 'unselectable'

  if (minPartySize != null && maxPartySize != null) {
    if (minPartySize <= partySize && partySize <= maxPartySize)
      return 'selectable'
  }
  if (maxPartySize != null && maxPartySize < partySize) return 'over-capacity'
  if (minPartySize != null && minPartySize > partySize) return 'under-capacity'

  return 'selectable'
}

function getSelectSeatStyle(state: SelectSeatState) {
  switch (state) {
    case 'over-capacity':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.bgBlack,
      }
    case 'selectable':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent40,
      }
    case 'selected':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent,
      }
    case 'under-capacity':
      return {
        borderColor: Colors.secondaryBlack,
        backgroundColor: Colors.black50,
      }
    case 'unselectable':
      return {
        borderColor: 'transparent',
        backgroundColor: Colors.black20,
      }
  }
}

// in hours
const minDuration = 0.5
const maxDuration = 23
const seatDurationInterval = 0.5

const SelectSeat = React.memo(
  ({
    mode,
    index,
    offsetX,
    startTime,
    onChangeDuration,
    selected,
    hiddenSelectedIcon,
    unselectable,
    tableSeat,
    partySize,
    onPress,
    style,
    isSp = false,
  }: {
    mode: ChartMode
    index: number
    offsetX: Animated.SharedValue<number>
    startTime: number
    // endTime: number
    onChangeDuration: (value: number) => void
    selected: boolean
    hiddenSelectedIcon: boolean
    unselectable: boolean
    tableSeat: ReturnType<typeof useTableSeats>['tableSeats'][number]
    partySize: number
    onPress: () => void
    style?: StyleProp<Animated.AnimateStyle<StyleProp<ViewStyle>>>
    isSp?: boolean
  }) => {
    const x = startTime / 3600
    const y = index
    const startX = useSharedValue(0)

    const snapPoints = range(
      minDuration,
      maxDuration + seatDurationInterval,
      seatDurationInterval
    ).map((hour) => getCellWidth(hour, isSp))

    // 30分から24時間まで
    const panGesture = Gesture.Pan()
      .onBegin(() => {
        // TODO: react-compiler for react-native-reanimated
        // eslint-disable-next-line react-compiler/react-compiler
        startX.value = offsetX.value
      })
      .onChange(({ translationX }) => {
        offsetX.value = startX.value + translationX
      })
      .onEnd(({ translationX, velocityX }) => {
        const point = snapPoint(
          startX.value + translationX,
          velocityX,
          snapPoints
        )
        offsetX.value = withSpring(point)
        const duration =
          snapPoints.indexOf(point) * seatDurationInterval + minDuration
        runOnJS(onChangeDuration)(duration)
      })

    const state = getSelectSeatState({
      selected,
      unselectable,
      partySize,
      minPartySize: tableSeat.min_party_size,
      maxPartySize: tableSeat.max_party_size,
    })

    return (
      <Animated.View
        style={[
          {
            position: 'absolute',
            height: getCellHeight(isSp),
            zIndex: 20,
          },
          mode === 'selectBlock' && {
            backgroundColor: Colors.accent40,
          },
          getCellStyle({ x, y, isSp }),
          style,
        ]}
      >
        <TouchableOpacity
          onPress={onPress}
          disabled={state === 'unselectable'}
          style={[
            {
              flex: 1,
              borderWidth: 4,
              borderRadius: 4,
              paddingVertical: 4,
              paddingHorizontal: 8,
              flexDirection: 'row',
              alignItems: 'center',
            },

            getSelectSeatStyle(state),
          ]}
        >
          {selected && !hiddenSelectedIcon && (
            <FontAwesomeIcon icon={faCheck} size={24} color="white" />
          )}
        </TouchableOpacity>
        {mode !== 'seatChange' && (
          <GestureDetector gesture={panGesture}>
            <Animated.View
              style={{
                position: 'absolute',
                top: 32 / 2,
                right: -32 / 2,
                width: 32,
                height: 32,
                backgroundColor: 'white',
                borderRadius: 32 / 2,
                borderWidth: 2,
                borderColor: Colors.accent,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faArrowsAltH}
                size={20}
                color={Colors.accent}
              />
            </Animated.View>
          </GestureDetector>
        )}
      </Animated.View>
    )
  }
)

function overlapsWith(
  left: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  },
  right: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
) {
  // 区間A, Bが重なっている場合、
  // (Aの開始地点) <= Bの終了地点) && (Aの終了地点 >= Bの開始地点)
  // という条件を満たしている
  return right.endAt.isAfter(left.startAt) && left.endAt.isAfter(right.startAt)
}

function getUnselectableTableSeatIds({
  mode,
  date,
  startTime,
  endTime,
  restaurantReservations,
  restaurantReservation: targetReservation,
}: {
  mode: ChartMode
  date: dayjs.Dayjs
  startTime: number
  endTime: number
  restaurantReservations: RestaurantReservationModel[]
  restaurantReservation?: RestaurantReservationModel
}) {
  const unselectableTableSeatIds: Array<TableSeatModel['id']> = []

  if (mode === 'selectBlock') {
    return unselectableTableSeatIds
  }
  const startAt = date.startOf('day').add(startTime, 'seconds')
  const endAt = date.startOf('day').add(endTime, 'seconds')

  if (mode !== 'seatChange') {
    for (const restaurantReservation of restaurantReservations) {
      for (const tableSeat of restaurantReservation.table_seats) {
        if (
          !unselectableTableSeatIds.includes(tableSeat.id) &&
          overlapsWith(
            {
              startAt,
              endAt,
            },
            {
              startAt: dayjs(restaurantReservation.start_at),
              endAt: dayjs(restaurantReservation.end_at),
            }
          )
        ) {
          if (mode === 'selectSeats') {
            // NOTE: 予約変更前と日付が違う場合は選択不可とする
            if (
              restaurantReservation != null &&
              dayjs(targetReservation?.start_at).format('YYYY-MM-DD') !==
                date.format('YYYY-MM-DD')
            )
              unselectableTableSeatIds.push(tableSeat.id)
            // NOTE: 予約変更時でも開始時刻が同じ予約とは席交換可能にする
            if (startAt.isSame(dayjs(restaurantReservation.start_at))) continue
          } else {
            // NOTE 予約変更以外のモード
            unselectableTableSeatIds.push(tableSeat.id)
          }
        }
      }
    }
  }

  if (mode === 'seatChange' && targetReservation?.id != null) {
    const reserved = restaurantReservations.find(
      (i) => i.id === targetReservation?.id
    )
    const reservedTableSeatIds = reserved?.table_seats.map((i) => i.id)
    // 同じ席IDが含まれている次の予約
    const nextReservations = restaurantReservations.filter(
      (candidateReservation) => {
        if (targetReservation?.id === candidateReservation.id) return false

        const tableSeatIds = candidateReservation.table_seats.map((i) => i.id)
        if (!reservedTableSeatIds?.some((a) => tableSeatIds.includes(a)))
          return false
        return endAt.isSameOrAfter(dayjs(candidateReservation.start_at))
      }
    )

    // 交換前の席に次予約が入っている場合
    const unSelectableReservations = restaurantReservations.filter(
      (candidateUnSelectableReservation) => {
        if (targetReservation?.id === candidateUnSelectableReservation.id)
          return false
        const isDuplicationDuration = nextReservations.some(
          (nextReservation) => {
            if (nextReservation.id === candidateUnSelectableReservation.id)
              return false
            // 次予約の開始時刻以降の予約席は判定しない
            if (
              dayjs(candidateUnSelectableReservation.start_at).isSameOrAfter(
                dayjs(nextReservation.start_at)
              )
            )
              return false
            // 交換できる席は終了時刻が次予約の開始時刻前のみである
            return dayjs(nextReservation.start_at).isBefore(
              dayjs(candidateUnSelectableReservation.end_at)
            )
          }
        )
        return isDuplicationDuration
      }
    )
    unSelectableReservations.forEach((unSelectableReservation) => {
      unSelectableReservation.table_seats.forEach((tableSeat) => {
        if (!unselectableTableSeatIds.includes(tableSeat.id)) {
          unselectableTableSeatIds.push(tableSeat.id)
        }
      })
    })
    // 席の変更・交換は自席を含めて同じ開始時刻の予約のみである
    const nextReservationIds = nextReservations.map((i) => i.id)
    restaurantReservations
      .filter((reservation) => {
        if (reservation.id === targetReservation?.id) return false
        if (nextReservationIds.includes(reservation.id)) return false

        // 時間が被ってても移動OKという整理になったため、一律でtrueにする
        // return startAt.isSame(reservation.start_at)
        return false
      })
      .forEach((notSameStartReservation) => {
        notSameStartReservation.table_seats.forEach((tableSeat) => {
          if (!unselectableTableSeatIds.includes(tableSeat.id)) {
            unselectableTableSeatIds.push(tableSeat.id)
          }
        })
      })
  }

  return unselectableTableSeatIds
}

function getOtherReservations({
  restaurantReservations,
}: {
  restaurantReservations: RestaurantReservationModel[] | undefined
}) {
  const reservations: RestaurantReservationModel[] = []

  if (restaurantReservations == null) {
    return reservations
  }

  for (const restaurantReservation of restaurantReservations) {
    if (restaurantReservation.table_seats.length === 0) {
      reservations.push(restaurantReservation)
    }
  }

  return reservations
}

function SelectSeatList({
  mode,
  selectParams,
  tableSeats,
  restaurantReservations,
  date,
  otherReservations,
}: {
  mode: ChartMode
  selectParams: SelectParams
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats']
  restaurantReservations: RestaurantReservationModel[]
  date: dayjs.Dayjs
  otherReservations: RestaurantReservationModel[]
}) {
  const {
    startTime,
    endTime,
    setEndTime,
    partySize,
    selectedSeatIds,
    onChangeSelectedSeatIds,
    restaurantReservation,
  } = selectParams

  const { width, sm } = useResponsive()
  const hours = (endTime - startTime) / 3600
  const offsetX = useSharedValue(getCellWidth(hours, width < sm))

  const unselectableTableSeatIds = useMemo(() => {
    return getUnselectableTableSeatIds({
      mode,
      date,
      startTime,
      endTime,
      restaurantReservations,
      restaurantReservation,
    })
  }, [
    date,
    endTime,
    mode,
    restaurantReservations,
    startTime,
    restaurantReservation,
  ])

  const onChangeDuration = (duration: number) => {
    const endTime = startTime + duration * 3600
    setEndTime(endTime)

    const unselectableTableSeatIds = getUnselectableTableSeatIds({
      mode,
      date,
      startTime,
      endTime,
      restaurantReservations,
      restaurantReservation,
    })

    onChangeSelectedSeatIds(
      selectedSeatIds?.filter(
        (seatId) => !unselectableTableSeatIds.includes(seatId)
      ) ?? []
    )
  }

  const toggleSelectSeat = (tableSeatId: TableSeatModel['id']) => {
    onChangeSelectedSeatIds(
      selectedSeatIds?.includes(tableSeatId)
        ? selectedSeatIds?.filter((seatId) => seatId !== tableSeatId)
        : [...(selectedSeatIds ?? []), tableSeatId]
    )
  }

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: offsetX.value,
    }
  })

  const reservedSeatIds = restaurantReservations.flatMap((i) =>
    i.table_seats.flatMap((t) => t.id)
  )

  return (
    <>
      {tableSeats.map((tableSeat, index) => {
        const reservedSeat = reservedSeatIds.includes(tableSeat.id)
        const selected = selectedSeatIds?.includes(tableSeat.id) ?? false
        return (
          <SelectSeat
            key={tableSeat.id}
            mode={mode}
            offsetX={offsetX}
            index={otherReservations.length + index}
            startTime={startTime}
            // endTime={endTime}
            onChangeDuration={onChangeDuration}
            selected={selected}
            unselectable={unselectableTableSeatIds.includes(tableSeat.id)}
            tableSeat={tableSeat}
            partySize={partySize}
            onPress={() => toggleSelectSeat(tableSeat.id)}
            style={[
              animatedStyle,
              reservedSeat && !selected && { opacity: 0.5 },
            ]}
            hiddenSelectedIcon={reservedSeat && !selected}
            isSp={width < sm}
          />
        )
      })}
    </>
  )
}

type Items = {
  reservations: RestaurantReservationModel[]
  reservationBlockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[]
}

function findOverlappingItemsByTableSeatId({
  tableSeatId,
  timeRange,
  reservations,
  reservationBlockPeriods,
}: {
  tableSeatId: TableSeatModel['id']
  timeRange: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
  reservations: RestaurantReservationModel[]
  reservationBlockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[]
}) {
  const overlappingItems: Items = {
    reservations: [],
    reservationBlockPeriods: [],
  }

  for (const reservation of reservations) {
    if (
      reservation.table_seats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(reservation.start_at),
          endAt: dayjs(reservation.end_at),
        }
      )
    ) {
      overlappingItems.reservations.push(reservation)
    }
  }

  for (const reservationBlockPeriod of reservationBlockPeriods) {
    if (
      reservationBlockPeriod.tableSeats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(Timestamp.toDate(reservationBlockPeriod.startAt!)),
          endAt: dayjs(Timestamp.toDate(reservationBlockPeriod.endAt!)),
        }
      )
    ) {
      overlappingItems.reservationBlockPeriods.push(reservationBlockPeriod)
    }
  }
  return overlappingItems
}

export interface ChartMethods {
  scrollToTime: ({
    time,
    animated,
  }: {
    time: number
    animated?: boolean
  }) => void
  refreshData: () => void
}

export type ChartProps = {
  restaurantId: number
  date: dayjs.Dayjs
  scrollViewProps?: Animated.AnimateProps<ScrollViewProps>
  onLoadStart?: () => void
  onLoadEnd?: () => void
  onReservationAdded?: () => void
  onStartSelectBlock?: ({
    startTime,
    seatId,
  }: {
    startTime: number
    seatId: TableSeatModel['id']
  }) => void
  onEndSelectBlock?: () => void
  mode: ChartMode
  selectParams?: SelectParams
}

function getStartTimeFromPosition(relativeX: number, isSp: boolean) {
  'worklet'
  const x =
    Math.round(
      relativeX / (isSp ? hourSpWidth : hourPcWidth) / seatDurationInterval
    ) * seatDurationInterval
  return x * 3600
}

function getSeatIdFromPosition(
  relativeY: number,
  isSp: boolean,
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats'][number][],
  unassignedSeatReservationLength: number
) {
  'worklet'
  const titleOffset = 1 // タイトル分の調整
  const offset = titleOffset + unassignedSeatReservationLength // 席が未指定の予約（ウォークイン、リクエスト）分の調整
  const columnIndex = Math.round(
    relativeY / (isSp ? seatSpHeight : seatPcHeight)
  )
  const index = columnIndex - offset
  return tableSeats[index]?.id
}

function getPartySize(
  tableSeat: ReturnType<typeof useTableSeats>['tableSeats'][number]
) {
  return `${tableSeat.min_party_size ?? ''}~${tableSeat.max_party_size ?? ''}`
}

/**
 * デフォルトで表示するリクエスト予約機能数
 */
const DEFAULT_SHOW_REQUEST_RESERVATION_SIZE = 4

export const Chart = React.forwardRef<ChartMethods, ChartProps>(
  (
    {
      restaurantId,
      date,
      mode = 'default',
      selectParams,
      scrollViewProps,
      onLoadStart,
      onLoadEnd,
      onReservationAdded,
      onStartSelectBlock,
      onEndSelectBlock,
    },
    ref
  ) => {
    const { tableSeats, isLoading: tableSeatsLoading } = useTableSeats(
      restaurantId,
      {}
    )
    const token = useToken()
    const { width, sm } = useResponsive()
    const navigation = useNavigation()
    const navigate = useNavigate()
    const alertRef = useRef<AlertMethods>(null)

    const {
      restaurantReservations: allRestaurantReservations,
      mutate,
      isLoading: restaurantReservationsLoading,
    } = useRestaurantReservations(
      restaurantId,
      {
        date: date.format('YYYY-MM-DD'),
      },
      {
        refreshInterval,
      }
    )

    const [refreshCount, setRefreshCount] = useState(0)

    const refreshReservationData = async () => {
      await mutate()
      setRefreshCount((pre) => pre + 1)
    }

    const restaurantReservations = useMemo(() => {
      return allRestaurantReservations.filter((item) => {
        // 予約の編集の場合は、自身の予約を除いて表示する
        if (mode === 'selectSeats') {
          if (selectParams?.restaurantReservation != null) {
            return item.id !== selectParams.restaurantReservation.id
          }
        }
        return true
      })
    }, [allRestaurantReservations, mode, selectParams])

    const otherReservations = useMemo(() => {
      return getOtherReservations({
        restaurantReservations,
      })
    }, [restaurantReservations])

    const {
      restaurantBusinessTimes,
      isLoading: restaurantBusinessTimesLoading,
    } = useRestaurantBusinessTimesByDate(restaurantId, {
      date: date.format('YYYY-MM-DD'),
    })
    const isRestaurantOpenAt = useCallback(
      (hour: number, min: number) => {
        return restaurantBusinessTimes?.some((restaurantBusinessTime) =>
          inRange(
            toSeconds(hour, min),
            restaurantBusinessTime.start_time,
            restaurantBusinessTime.end_time
          )
        )
      },
      [restaurantBusinessTimes]
    )
    const { data: restaurantReservationBlocks } =
      restaurantReservationBlockService.useListPeriod({
        restaurantId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.format('YYYY-MM-DD'),
      })

    const [isShowMoreRequestReservations, setIsShowMoreRequestReservation] =
      useState(false)
    const { requestReservations: requestReservationsData = [] } =
      useRestaurantRequestReservations(restaurantId, { date })

    const filteredRequestReservations = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation.reservation?.reservation_change_request?.status ===
          'requesting'
        ) {
          const fromReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .from_reserved_at
          ).format('YYYY-MM-DD')
          const toReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .to_reserved_at
          ).format('YYYY-MM-DD')
          const condition =
            // 同日の変更の場合は表示する
            fromReservationDate === toReservationDate ??
            // 変更前のChart日付では予約行のreservationで処理表示するため、リクエスト予約行では表示しない
            date.format('YYYY-MM-DD') !== fromReservationDate
          return condition
        }
        return true
      })
    }, [requestReservationsData, date])

    const reservationChangeRequests = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation?.reservation?.reservation_change_request != null
        ) {
          // 変更リクエストが変更後日時にも含まれるがChart画面では表示しない
          const condition =
            date.format('YYYY-MM-DD') ===
            dayjs(
              requestReservation.reservation.reservation_change_request
                .to_reserved_at
            ).format('YYYY-MM-DD')
          return !condition
        }
        return false
      })
    }, [requestReservationsData, date])

    const requestReservations = useMemo(
      () =>
        mode === 'default'
          ? filteredRequestReservations.slice(
              0,
              isShowMoreRequestReservations
                ? undefined
                : DEFAULT_SHOW_REQUEST_RESERVATION_SIZE
            )
          : [],
      [isShowMoreRequestReservations, mode, filteredRequestReservations]
    )

    const scrollViewRef = useRef<ScrollView>(null)
    const scrollViewContentOffsetRef = useRef(0)

    useImperativeHandle(ref, () => ({
      scrollToTime: ({ time, animated = false }) => {
        setTimeout(() => {
          const { hours: hour, minutes: minute } = toHoursAndMinutes(time)
          scrollViewRef.current?.scrollTo({
            x:
              scrollViewContentOffsetRef.current > 0
                ? scrollViewContentOffsetRef.current
                : (hour + minute / 60) *
                  (width < sm ? hourSpWidth : hourPcWidth),
            y: 0,
            animated,
          })
        }, 0)
      },
      refreshData: refreshReservationData,
    }))

    const headerTranslateY = useSharedValue(0)

    const onScroll = useAnimatedScrollHandler((event) => {
      const scrollY = event.contentOffset.y
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      headerTranslateY.value =
        scrollY < scrollViewPaddingY ? 0 : scrollY - scrollViewPaddingY
    })

    const isLoading =
      tableSeatsLoading ||
      restaurantReservationsLoading ||
      restaurantBusinessTimesLoading

    const prevIsLoading = usePrevious(isLoading)
    const prevDate = usePrevious(date)
    const prevAllRestaurantReservations = usePrevious(allRestaurantReservations)

    useEffect(() => {
      if (prevDate !== date) {
        onLoadStart?.()
      }
    }, [date, onLoadStart, prevDate])

    useEffect(() => {
      if (
        (prevDate !== date && !isLoading) ||
        (prevIsLoading !== isLoading && !isLoading)
      ) {
        onLoadEnd?.()
      }
    }, [date, isLoading, onLoadEnd, prevDate, prevIsLoading])

    const handleReservationAdded = useEffectEvent(() => {
      onReservationAdded?.()
    })

    useEffect(() => {
      // NOTE: 新規予約追加後データに反映されるまでを記録する
      if (
        (allRestaurantReservations?.length ?? 0) >
        (prevAllRestaurantReservations?.length ?? 0)
      ) {
        handleReservationAdded()
      }
    }, [
      prevAllRestaurantReservations,
      allRestaurantReservations,
      handleReservationAdded,
    ])

    const [menuPopOver, setMenuPopOver] = useState<
      | {
          visible: false
          absoluteX?: number
          absoluteY?: number
          items?: Items
        }
      | {
          visible: true
          absoluteX: number
          absoluteY: number
          items: Items
        }
    >({
      visible: false,
    })

    const headerCellStyle = useAnimatedStyle(() => {
      return { transform: [{ translateY: headerTranslateY.value }] }
    })

    const onPressBackgroundView = Gesture.Tap().onEnd((event, success) => {
      if (!success) return

      if (mode === 'default') {
        const startTime = getStartTimeFromPosition(event.x, width < sm)
        const seatId = getSeatIdFromPosition(
          event.y,
          width < sm,
          tableSeats,
          requestReservations.length + otherReservations.length
        )
        if (onStartSelectBlock) {
          runOnJS(onStartSelectBlock)({ startTime, seatId })
        }
      } else if (mode === 'selectBlock') {
        const { startTime, endTime } = selectParams!
        const tappedTime = getStartTimeFromPosition(event.x, width < sm)
        // 選択ブロックの外側をタップした場合は終了する
        if (
          onEndSelectBlock &&
          (tappedTime < startTime || tappedTime > endTime)
        ) {
          runOnJS(onEndSelectBlock)()
        }
      }
    })
    onPressBackgroundView.enabled(mode === 'default' || mode === 'selectBlock')

    const onPressReservation = ({
      restaurantReservation,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservation: RestaurantReservationModel
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(restaurantReservation.start_at),
          endAt: dayjs(restaurantReservation.end_at),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservationBlockPeriods.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
      }

      if (restaurantReservation.reservation_change_request != null) {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/change_requests/${restaurantReservation.id}`
          )
        } else {
          navigation.navigate('ChangeRequestReservation', {
            data: {
              ...restaurantReservation,
              restaurant_course:
                restaurantReservation.reservation_courses.length > 0
                  ? restaurantReservation.reservation_courses[0]
                      .restaurant_course
                  : undefined,
            },
            customer: restaurantReservation.customers[0],
          })
        }
        return
      }

      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
        )
      } else {
        navigation.navigate('ReservationsShow', {
          restaurantReservationId: restaurantReservation.id,
        })
      }
    }

    const onPressReservationBlockPeriod = ({
      restaurantReservationBlockPeriod,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservationBlockPeriod: ListPeriodResponse_RestaurantReservationBlockPeriod
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.startAt!)
          ),
          endAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.endAt!)
          ),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservations.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
        return
      }

      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations/blocks/${restaurantReservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${restaurantReservationBlockPeriod.startAt?.seconds}&endAt=${restaurantReservationBlockPeriod.endAt?.seconds}`
        )
      } else {
        navigation.navigate('ReservationBlocksShow', {
          restaurantReservationBlockId:
            restaurantReservationBlockPeriod.restaurantReservationBlock?.id,
          startAt: restaurantReservationBlockPeriod.startAt,
          endAt: restaurantReservationBlockPeriod.endAt,
        })
      }
    }

    const onMoveTableSeat = async (
      restaurantReservation: RestaurantReservationModel,
      selectTableSeatId: string,
      moveToTableSeatId: string
    ) => {
      const alert = Platform.select({
        web: alertRef.current?.alert,
        default: Alert.alert,
      })
      // 移動先シートの人数上限が超えていないか
      const tableSeat = tableSeats.find((ts) => ts.id === moveToTableSeatId)
      if (tableSeat == null) {
        onError(new Error(t('席情報が取得できませんでした。')))
        refreshReservationData()
        return
      }

      if (tableSeat != null) {
        // 移動前に保持している席で許容出来る席数
        const allMaxPartySize = restaurantReservation.table_seats.reduce(
          (acc, { id }) => {
            if (id !== moveToTableSeatId) return acc
            const ts = tableSeats.find((i) => i.id === id)
            if (ts != null) acc += ts?.max_party_size ?? 0
            return acc
          },
          0
        )
        const requireSeatSize =
          restaurantReservation.party_size - allMaxPartySize
        if (requireSeatSize > (tableSeat?.max_party_size ?? 0)) {
          // 席変更後の席数が足りないためエラーを出す
          alert(t('選択できないです'), t('席を変更しますか？'), [
            {
              text: t('いいえ'),
              onPress: () => {
                refreshReservationData()
              },
              style: 'cancel',
            },
            {
              text: t('はい'),
              onPress: () => {
                if (Platform.OS === 'web') {
                  navigate(
                    `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/change_seat`
                  )
                } else {
                  navigation.navigate('ChangeSeatForm', {
                    restaurantReservationId: restaurantReservation.id,
                  })
                }
              },
            },
          ])
          return
        }
      }

      // 移動先シートが他で予約されているか
      const bookingReservations = allRestaurantReservations
        .filter((i) => i.id !== restaurantReservation.id)
        .filter((i) =>
          i.table_seats
            .flatMap((t) => t.id)
            .some((tid) => moveToTableSeatId === tid)
        )
      //  席の移動
      // 新しいシートId列 例）１、２、A
      const newTableSeatId = restaurantReservation.table_seats
        .map((i) => i.id)
        .filter((i) => i !== selectTableSeatId)
      newTableSeatId.push(moveToTableSeatId)

      const runSimpleMoveTableSeat = () => {
        alert(t('席の移動'), t('席を移動しますか？'), [
          {
            text: t('いいえ'),
            onPress: refreshReservationData,
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: async () => {
              if (token == null) return
              const { error } = await updateRestaurantReservation(
                token,
                restaurantId,
                restaurantReservation.id,
                {
                  table_seat_ids: newTableSeatId,
                }
              )
              if (error != null) return
              displayToastSuccess(
                t('予約を更新しました'),
                undefined,
                width < sm ? { marginBottom: 48 } : undefined
              )
              refreshReservationData()
            },
          },
        ])
      }
      if (bookingReservations.length === 0) {
        runSimpleMoveTableSeat()
        return
      }

      // 時刻のかぶっているものがあるか
      const duplicateTimeReservations = bookingReservations.filter(
        (br) =>
          dayjs(br.end_at).isAfter(restaurantReservation.start_at) &&
          dayjs(restaurantReservation.end_at).isAfter(br.start_at)
      )

      if (
        duplicateTimeReservations.some(
          (dtr) => !dayjs(dtr.start_at).isSame(restaurantReservation.start_at)
        )
      ) {
        // 開始時刻が同じでない予約が入っているか
        alert(t('他の予約と被っています'), t('席を変更しますか？'), [
          {
            text: t('いいえ'),
            onPress: refreshReservationData,
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: () => {
              if (Platform.OS === 'web') {
                navigate(
                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/change_seat`
                )
              } else {
                navigation.navigate('ChangeSeatForm', {
                  restaurantReservationId: restaurantReservation.id,
                })
              }
            },
          },
        ])
        return
      }
      const exchangeReservation = bookingReservations.find((br) =>
        dayjs(br.start_at).isSame(restaurantReservation.start_at)
      )

      if (exchangeReservation == null) {
        runSimpleMoveTableSeat()
        return
      }

      alert(t('席の入れ替え'), t('席を入れ替えますか？'), [
        {
          text: t('いいえ'),
          onPress: () => {
            refreshReservationData()
          },
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            if (token == null) return
            /**
             * NOTE
             * 予約１：テーブル１、２、３
             * 予約２：テーブルA、B、C
             * のとき、予約１をテーブル１、２，Aとしたい場合は
             * table_seat_ids = １、２、A
             * other_table_seats = ３、B、C
             * とする。
             * このとき、３を交換元、Aを交換先と呼ぶ
             */

            // 交換先の新しいシートId列 例）B、C、３
            const bookingReservationNewTableSeatId =
              exchangeReservation.table_seats
                .map((i) => i.id)
                .filter((i) => i !== moveToTableSeatId)
            bookingReservationNewTableSeatId.push(selectTableSeatId)

            const params = {
              end_at: dayjs(restaurantReservation.end_at).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              table_seat_ids: newTableSeatId,
              other_table_seats: [
                {
                  reservation_id: exchangeReservation.id,
                  table_seat_ids: bookingReservationNewTableSeatId,
                },
              ],
            }
            const { error } = await exchangeRestaurantReservationTableSeats(
              token,
              restaurantId,
              restaurantReservation.id,
              params
            )
            if (error != null) return
            displayToastSuccess(
              t('席を交換しました'),
              undefined,
              width < sm ? { marginBottom: 48 } : undefined
            )
            refreshReservationData()
          },
        },
      ])
    }

    return (
      <>
        {isLoading && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Loading />
          </View>
        )}
        <Animated.ScrollView
          {...scrollViewProps}
          contentContainerStyle={[
            {
              position: 'relative',
              paddingVertical: width < sm ? 8 : scrollViewPaddingY,
              paddingHorizontal: width < sm ? 0 : scrollViewPcPaddingX,
            },
            Platform.OS === 'web' ? { height: '100%' } : {},
            scrollViewProps?.contentContainerStyle,
          ]}
          onScroll={onScroll}
          scrollEventThrottle={16}
        >
          <View
            style={[
              {
                flexDirection: 'row',
                height: Platform.OS === 'web' ? '100%' : undefined,
              },
            ]}
          >
            <View
              style={{
                marginRight: width < sm ? 4 : 20,
                marginLeft: width < sm ? 4 : 0,
              }}
            >
              {width >= sm && (
                <Animated.View
                  style={[{ flexDirection: 'row', zIndex: 1 }, headerCellStyle]}
                >
                  <HeaderCell width={seatPcWidth} text={t('席')} isFirst />
                  <HeaderCell
                    width={partySizeWidth}
                    text={t('定員')}
                    isFirst={false}
                    justifyContent="center"
                  />
                </Animated.View>
              )}
              <View style={width < sm && { marginTop: cellHeight }}>
                {otherReservations.length > 0 && (
                  <View key={`other`} style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        width:
                          width < sm
                            ? seatSpWidth
                            : seatPcWidth + partySizeWidth,
                        height:
                          (width < sm ? seatSpHeight : seatPcHeight) *
                          otherReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderLeftWidth: width < sm ? 0 : borderWidth,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : borderWidth,
                        borderBottomWidth: borderWidth * 2,
                        paddingHorizontal: width < sm ? 8 : paddingHorizontal,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View style={{ marginTop: 8 }}>
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '600',
                            }}
                          >
                            {t('座席指定なし')}
                          </Text>
                          <Text
                            style={{
                              fontSize: 12,
                              marginTop: -18,
                              alignSelf: 'flex-end',
                            }}
                          >
                            {t('{{count}}件', {
                              count: otherReservations.length,
                            })}
                          </Text>
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: 14,
                            marginTop: 14,
                          }}
                        >
                          {t('席指定なしの予約\n{{count}}件', {
                            count: otherReservations.length,
                          })}
                        </Text>
                      )}
                    </View>
                  </View>
                )}
                {requestReservations.length > 0 && (
                  <View
                    key={'request_reservation'}
                    style={{ flexDirection: 'row' }}
                  >
                    <View
                      style={{
                        width:
                          width < sm
                            ? seatSpWidth
                            : seatPcWidth + partySizeWidth,
                        height:
                          (width < sm ? seatSpHeight : seatPcHeight) *
                          requestReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderLeftWidth: width < sm ? 0 : borderWidth,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : borderWidth,
                        borderBottomWidth: borderWidth * 2,
                        paddingHorizontal: width < sm ? 8 : paddingHorizontal,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View style={{ marginTop: 8 }}>
                          {/* eslint-disable ar-i18n/require-translation-ja */}
                          <Trans
                            i18nKey={
                              '<0>リクエスト</0><1><0>予約</0><1>{{count}}件</1></1>'
                            }
                            values={{
                              count: filteredRequestReservations.length,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: '600',
                              }}
                            >
                              {'リクエスト'}
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'baseline',
                                width: 66,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: '600',
                                }}
                              >
                                {'予約'}
                              </Text>
                              <Text
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {`${filteredRequestReservations.length}`}
                                {'件'}
                              </Text>
                            </View>
                          </Trans>
                          {/* eslint-enable ar-i18n/require-translation-ja */}
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: 14,
                            marginTop: 14,
                          }}
                        >
                          {t('リクエスト予約{{count}}件', {
                            count: filteredRequestReservations.length,
                          })}
                        </Text>
                      )}
                      {filteredRequestReservations.length >
                        DEFAULT_SHOW_REQUEST_RESERVATION_SIZE && (
                        <Button
                          mode="text"
                          onPress={() => {
                            setIsShowMoreRequestReservation(
                              !isShowMoreRequestReservations
                            )
                          }}
                          variant="primary"
                          style={width < sm && { paddingHorizontal: 0 }}
                        >
                          <Text
                            style={[
                              width < sm && {
                                color: Colors.primary,
                                height: 40,
                              },
                              {
                                marginTop: 14,
                                fontSize: 14,
                              },
                            ]}
                          >
                            {width < sm
                              ? isShowMoreRequestReservations
                                ? t('とじる')
                                : t('もっとみる')
                              : isShowMoreRequestReservations
                                ? t('閉じる')
                                : t('もっと見る')}
                          </Text>
                        </Button>
                      )}
                    </View>
                  </View>
                )}
                {tableSeats?.map((tableSeat, index) => {
                  return (
                    <View key={tableSeat.id} style={{ flexDirection: 'row' }}>
                      <View
                        style={[
                          {
                            borderColor: Colors.border,
                            backgroundColor: Colors.white,
                          },
                          width < sm
                            ? {
                                width: seatSpWidth,
                                height: seatSpHeight,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                borderRightWidth: 0,
                                borderBottomWidth:
                                  tableSeats.length - 1 === index
                                    ? 0
                                    : borderWidth,
                                paddingHorizontal: 8,
                              }
                            : {
                                width: seatPcWidth,
                                height: seatPcHeight,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderLeftWidth: borderWidth,
                                borderRightWidth: borderWidth,
                                borderBottomWidth: borderWidth,
                                paddingHorizontal,
                              },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            lineHeight: 21,
                            fontWeight: width < sm ? '600' : '300',
                          }}
                          numberOfLines={width < sm ? 1 : undefined}
                          ellipsizeMode={width < sm ? 'tail' : undefined}
                        >
                          {tableSeat.name}
                        </Text>
                        {width < sm && (
                          <Text style={{ fontSize: 12, lineHeight: 18 }}>
                            {t('{{party_size}}名', {
                              party_size: getPartySize(tableSeat),
                            })}
                          </Text>
                        )}
                      </View>
                      {width >= sm && (
                        <View
                          style={{
                            width: partySizeWidth,
                            height: seatPcHeight,
                            borderColor: Colors.border,
                            borderRightWidth: borderWidth,
                            borderBottomWidth: borderWidth,
                            paddingHorizontal,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: Colors.white,
                          }}
                        >
                          <Text style={{ fontSize: 14, lineHeight: 21 }}>
                            {getPartySize(tableSeat)}
                          </Text>
                        </View>
                      )}
                    </View>
                  )
                })}
              </View>
            </View>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{
                flex: 1,
                position: 'relative',
                height:
                  (width >= sm ? seatPcHeight : seatSpHeight) *
                    (tableSeats?.length ?? 0) + // 席数分
                  (width < sm ? seatSpHeight : seatPcHeight) *
                    otherReservations.length + // 未指定席分
                  24, // ヘッダー分
              }}
              ref={scrollViewRef}
              onScroll={(event) => {
                scrollViewContentOffsetRef.current =
                  event.nativeEvent.contentOffset.x
              }}
              scrollEventThrottle={16}
            >
              <GestureDetector gesture={onPressBackgroundView}>
                <View
                  style={{
                    flex: 1,
                    position: 'relative',
                  }}
                >
                  {mode !== 'default' && (
                    <SelectSeatList
                      mode={mode}
                      selectParams={selectParams!}
                      tableSeats={tableSeats}
                      restaurantReservations={restaurantReservations}
                      date={date}
                      otherReservations={otherReservations}
                    />
                  )}
                  <View
                    style={{
                      flexDirection: 'column',
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <View style={{ zIndex: 1 }}>
                      {otherReservations.map((restaurantReservation, index) => {
                        return (
                          <Reservation
                            key={`${restaurantReservation.id}_other`}
                            type={
                              mode === 'default' ? 'default' : 'unselectable'
                            }
                            index={index}
                            restaurantReservation={restaurantReservation}
                            date={date}
                            onPress={() => {
                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                                )
                              } else {
                                navigation.navigate('ReservationsShow', {
                                  restaurantReservationId:
                                    restaurantReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {requestReservations.map((requestReservation, index) => {
                        return (
                          <Reservation
                            key={`${requestReservation.id}_request_reservation`}
                            index={index}
                            type="default"
                            restaurantReservation={{
                              ...requestReservation,
                              memo: requestReservation.memo ?? '',
                              adult_party_size:
                                requestReservation.adult_party_size,
                              customers:
                                requestReservation.customer == null
                                  ? []
                                  : [requestReservation.customer],
                              kind: 'normal',
                              smart_payment: null,
                            }}
                            date={date}
                            onPress={() => {
                              if (requestReservation.reservation != null) {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/change_requests/${requestReservation.reservation.uuid}`
                                  )
                                } else {
                                  navigation.navigate(
                                    'ChangeRequestReservation',
                                    {
                                      data: requestReservation.reservation,
                                      customer: requestReservation.customer,
                                    }
                                  )
                                }
                                return
                              }

                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                )
                              } else {
                                navigation.navigate('RequestReservation', {
                                  id: requestReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {reservationChangeRequests.map(
                        (requestReservation, index) => {
                          index +=
                            requestReservations.length +
                            otherReservations.length
                          return (
                            <Reservation
                              key={`${requestReservation.id}_change_request_reservation`}
                              index={index}
                              type="default"
                              restaurantReservation={{
                                ...requestReservation,
                                memo: requestReservation.memo ?? '',
                                adult_party_size:
                                  requestReservation.adult_party_size,
                                customers:
                                  requestReservation.customer == null
                                    ? []
                                    : [requestReservation.customer],
                                kind: 'normal',
                                smart_payment: null,
                              }}
                              date={date}
                              onPress={() => {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                  )
                                } else {
                                  navigation.navigate('RequestReservation', {
                                    id: requestReservation.id,
                                  })
                                }
                              }}
                              isWarning
                            />
                          )
                        }
                      )}
                      {restaurantReservations?.map((restaurantReservation) => {
                        return restaurantReservation.table_seats.map(
                          (tableSeat) => {
                            let index = tableSeats.findIndex(
                              ({ id }) => tableSeat.id === id
                            )

                            if (index < 0) {
                              // アーカイブされた席に紐づいている場合は表示しない
                              return null
                            }

                            index +=
                              requestReservations.length +
                              otherReservations.length

                            return (
                              <Reservation
                                key={`${restaurantReservation.id}_${tableSeat.id}_${refreshCount}`}
                                type={
                                  mode === 'default'
                                    ? 'default'
                                    : 'unselectable'
                                }
                                index={index}
                                restaurantReservation={restaurantReservation}
                                onPress={({ absoluteX, absoluteY }) =>
                                  onPressReservation({
                                    restaurantReservation,
                                    tableSeatId: tableSeat.id,
                                    absoluteX,
                                    absoluteY,
                                  })
                                }
                                date={date}
                                onDragEnd={(nextIndex) => {
                                  const tableSeatIndex =
                                    nextIndex -
                                    requestReservations.length -
                                    otherReservations.length
                                  const nextTable = tableSeats[tableSeatIndex]
                                  if (nextTable != null) {
                                    onMoveTableSeat(
                                      restaurantReservation,
                                      tableSeat.id,
                                      nextTable.id
                                    )
                                  } else {
                                    onError(
                                      new Error(
                                        t('移動先の席を特定できませんでした。')
                                      )
                                    )
                                    refreshReservationData()
                                  }
                                }}
                                isWarning={
                                  restaurantReservation.reservation_change_request !=
                                  null
                                }
                              />
                            )
                          }
                        )
                      })}
                      {restaurantReservationBlocks?.restaurantReservationBlockPeriods.map(
                        (restaurantReservationBlockPeriod) => {
                          return restaurantReservationBlockPeriod.tableSeats.map(
                            (seat) => {
                              const index =
                                requestReservations.length +
                                otherReservations.length +
                                tableSeats.findIndex(({ id }) => seat.id === id)
                              return (
                                <RestaurantReservationBlockPeriod
                                  key={`${restaurantReservationBlockPeriod.id}_${seat.id}`}
                                  type={
                                    mode === 'default'
                                      ? 'default'
                                      : 'unselectable'
                                  }
                                  index={index}
                                  restaurantReservationBlockPeriod={
                                    restaurantReservationBlockPeriod
                                  }
                                  onPress={({ absoluteX, absoluteY }) => {
                                    onPressReservationBlockPeriod({
                                      restaurantReservationBlockPeriod,
                                      tableSeatId: seat.id,
                                      absoluteX,
                                      absoluteY,
                                    })
                                  }}
                                  isSp={width < sm}
                                />
                              )
                            }
                          )
                        }
                      )}
                    </View>
                    <Animated.View
                      style={[
                        {
                          flexDirection: 'row',
                          zIndex: 2,
                        },
                        headerCellStyle,
                      ]}
                    >
                      {hours.map((hour, index) => (
                        <HeaderCell
                          key={`${hour}`}
                          width={width < sm ? hourSpWidth : hourPcWidth}
                          text={`${hour}:00`}
                          isFirst={index === 0}
                          isSp={width < sm}
                        />
                      ))}
                    </Animated.View>
                    <HourCellList
                      otherReservations={otherReservations}
                      requestReservations={requestReservations}
                      tableSeats={tableSeats}
                      isRestaurantOpenAt={isRestaurantOpenAt}
                      isSp={width < sm}
                    />
                  </View>
                </View>
              </GestureDetector>
            </ScrollView>
          </View>
          <Menu
            visible={menuPopOver.visible}
            anchor={{
              x: menuPopOver?.absoluteX ?? 0,
              y: menuPopOver?.absoluteY ?? 0,
            }}
            onDismiss={() => {
              setMenuPopOver((menuPopOver) => ({
                ...menuPopOver,
                visible: false,
              }))
            }}
            contentStyle={{
              paddingVertical: 0,
            }}
          >
            {menuPopOver.items?.reservations.map((restaurantReservation) => {
              return (
                <Menu.Item
                  key={`${restaurantReservation.id}`}
                  onPress={() => {
                    setMenuPopOver((menuPopOver) => ({
                      ...menuPopOver,
                      visible: false,
                    }))
                    if (Platform.OS === 'web') {
                      navigate(
                        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                      )
                    } else {
                      navigation.navigate('ReservationsShow', {
                        restaurantReservationId: restaurantReservation.id,
                      })
                    }
                  }}
                  title={t('{{name}} の予約', {
                    name: getReservationText(restaurantReservation) || '-',
                  })}
                  contentStyle={{
                    minWidth: 240,
                  }}
                />
              )
            })}
            {menuPopOver.items?.reservationBlockPeriods.map(
              (reservationBlockPeriod) => {
                return (
                  <Menu.Item
                    key={`${reservationBlockPeriod.id}`}
                    onPress={() => {
                      setMenuPopOver((menuPopOver) => ({
                        ...menuPopOver,
                        visible: false,
                      }))
                      if (Platform.OS === 'web') {
                        navigate(
                          `/restaurants/${restaurantId}/reservations/blocks/${reservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${reservationBlockPeriod.startAt?.seconds}&endAt=${reservationBlockPeriod.endAt?.seconds}`
                        )
                      } else {
                        navigation.navigate('ReservationBlocksShow', {
                          restaurantReservationBlockId:
                            reservationBlockPeriod.restaurantReservationBlock
                              ?.id,
                          startAt: reservationBlockPeriod.startAt,
                          endAt: reservationBlockPeriod.endAt,
                        })
                      }
                    }}
                    title={t('ブロック')}
                    contentStyle={{
                      minWidth: 240,
                    }}
                  />
                )
              }
            )}
          </Menu>
        </Animated.ScrollView>
        <View
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents="box-none"
        >
          <AlertProvider ref={alertRef} />
        </View>
      </>
    )
  }
)
