import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import React, { useMemo, useRef } from 'react'
import { Platform, TextInput as RNTextInput, View } from 'react-native'

import {
  lineHeightMultiplier,
  Text,
} from '@hello-ai/ar_shared/src/components/Text'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { getFormatTime, toSeconds } from '@hello-ai/ar_shared/src/modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { range } from 'lodash'
import produce from 'immer'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { LegacyButton } from '@hello-ai/ar_shared/src/components/LegacyButton'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { TableMenu as TableMenuModel } from '@hello-ai/ar_shared/src/types/ForR/TableMenu'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import {
  SelectedImage,
  SelectImage,
} from '@hello-ai/ar_shared/src/components/SelectImage'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { SupportedLocale } from '@hello-ai/ar_shared/src/modules/locale'
import * as Linking from 'expo-linking'
import { useRestaurant } from '../../models/Restaurant'
import {
  CreateParams,
  RestaurantCourse as RestaurantCourseModel,
  UpdateParams,
} from '../../models/RestaurantCourse'
import {
  confirmSaveIncompleteChangesAsync,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  TranslationFormItem,
  TranslationFormList,
} from '../Translation'
import { parseNumber } from '@hello-ai/ar_shared/src/modules/number'

type FormCancelPolicy = {
  cancelPercent: string
  cancelTime: number
} & (
  | {
      id?: undefined
      Destroy: false
    }
  | {
      id: string
      Destroy: boolean
    }
)

const parseSeconds = (seconds: number) => {
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds % 3600) / 60)
  return {
    hour,
    minute,
  }
}

const UNTIL_DAY_BEFORE_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 180 + 1).map((value) => {
    return value === 0
      ? { label: t('当日'), value }
      : value === 1
        ? { label: t('前日'), value }
        : {
            label: t('{{text}}日前', {
              text: value,
            }),
            value,
          }
  }),
]

function getTimeBeforeLabel(hours: number, mins: number) {
  let str = ''

  if (hours > 0) {
    str += t('{{text}}時間', {
      text: hours,
    })
  }
  if (mins > 0 || (hours === 0 && mins === 0)) {
    str += t('{{text}}分', {
      text: mins,
    })
  }
  str += t('前')
  return str
}

const UNTIL_TIME_BEFORE_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('時間前'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getTimeBeforeLabel(hours, mins),
      value: toSeconds(hours, mins),
    }))
  ),
]

const UNTIL_SPECIFIC_TIME_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

type RestaurantCourseFormProps = {
  restaurantId: Restaurant['id']
  restaurantCourseId: RestaurantCourseModel['id'] | undefined
  restaurantCourse: RestaurantCourseModel | undefined
  tableMenus: TableMenuModel[]
  restaurantCourseQuestions: RestaurantCourseModel['questions'] | undefined
  image: RestaurantCourseModel['image_urls'] | undefined
  onCreate: (params: CreateParams) => Promise<void>
  onCreateError: (errors: string[]) => void
  onUpdate: (params: UpdateParams) => Promise<void>
  onUpdateError: (errors: string[]) => void
  onArchive: () => void
  sortedLocales: SupportedLocale[]
  createTranslation: (params: {
    text: string
    source_lang: string
    target_langs: string[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: unknown
  }>
  formatCurrencyInput?: (value?: string, useAbsoluteValue?: boolean) => string
}

type LocaleValue = {
  [key in SupportedLocale]: string
}

const formSpace = 40
const cancelPolicyTimeItems = [
  {
    label: t('時間'),
    value: 0,
  },
  ...range(1, 23 + 1).map((hours) => ({
    label: t('{{text}}時間前', {
      text: hours,
    }),
    value: toSeconds(hours, 0),
  })),
  ...range(1, 30 + 1).map((days) => ({
    label: t('{{hours}}時間前（{{days}}日前）', {
      hours: 24 * days,
      days,
    }),
    value: toSeconds(days * 24, 0),
  })),
]

export function validate({
  cancelPolicies,
  price,
  minPartySize,
  maxPartySize,
  stockSetting,
}: {
  cancelPolicies: FormCancelPolicy[]
  price: number
  minPartySize: number | null
  maxPartySize: number | null
  stockSetting: UpdateParams['restaurant_course_stock_setting']
}) {
  const errors: string[] = []

  for (const [index, cancelPolicy] of cancelPolicies.entries()) {
    if (cancelPolicy.Destroy) continue
    if (cancelPolicy.cancelTime === 0) {
      errors.push(
        t('キャンセル料 {{text}}の時間を選択してください', {
          text: index + 1,
        })
      )
      break
    }
    if (cancelPolicy.cancelPercent === '') {
      errors.push(
        t('キャンセル料 {{text}}のパーセンテージを入力してください', {
          text: index + 1,
        })
      )
      break
    }
  }

  if (isNaN(price)) {
    errors.push(t('料金を半角数字で入力してください'))
  }

  if (minPartySize == null && maxPartySize != null) {
    errors.push(t('利用可能な最小人数を入力してください'))
  }
  if (maxPartySize == null && minPartySize != null) {
    errors.push(t('利用可能な最大人数を入力してください'))
  }

  if (minPartySize != null && isNaN(minPartySize)) {
    errors.push(t('利用可能な最小人数を半角数字で入力してください'))
  }
  if (maxPartySize != null && isNaN(maxPartySize)) {
    errors.push(t('利用可能な最大人数を半角数字で入力してください'))
  }

  if (
    minPartySize != null &&
    maxPartySize != null &&
    minPartySize > maxPartySize
  ) {
    errors.push(t('利用可能な最小人数は最大人数以下である必要があります'))
  }
  if (minPartySize != null && maxPartySize != null && minPartySize <= 0) {
    errors.push(t('利用可能な最大人数は1人以上である必要があります'))
  }
  if (minPartySize != null && maxPartySize != null && maxPartySize <= 0) {
    errors.push(t('利用可能な最大人数は1人以上である必要があります'))
  }

  if (
    stockSetting != null &&
    isNaN(stockSetting.quantity) &&
    stockSetting._destroy === false
  ) {
    errors.push(t('在庫数を半角数字で入力してください'))
  }

  return { errors }
}

const getStockType = (
  stockType: NonNullable<
    RestaurantCourseModel['restaurant_course_stock_setting']
  >['stock_type']
): 0 => {
  switch (stockType) {
    case 'daily':
      return 0
  }
}

type RestaurantCourseReservationPeriodPolicy = NonNullable<
  CreateParams['restaurant_course_reservation_period_policy']
>
type SinceTypeKey = NonNullable<
  RestaurantCourseReservationPeriodPolicy['since_type']
>
const SINCE_TYPE: {
  [K in SinceTypeKey]: K
} = {
  each_day: 'each_day',
  bulk: 'bulk',
  business_time_based: 'business_time_based',
}
type UntilTypeKey = NonNullable<
  RestaurantCourseReservationPeriodPolicy['until_type']
>
const UNTIL_TYPE: {
  [K in UntilTypeKey]: K
} = {
  offset: 'offset',
  specific: 'specific',
  business_time_based: 'business_time_based',
}

const INITIAL_RESTAURANT_COURSE_RESERVATION_PERIOD_POLICY: RestaurantCourseReservationPeriodPolicy =
  {
    since_day_before: 0,
    since_month_before: 0,
    since_specific_day: 0,
    since_specific_time: 0,
    since_type: SINCE_TYPE.business_time_based,
    until_enabled: false,
    until_day_before: 0,
    until_specific_time: 0,
    until_time_before: 0,
    until_type: UNTIL_TYPE.business_time_based,
  }

export function RestaurantCourseForm({
  restaurantId,
  restaurantCourseId,
  restaurantCourse,
  tableMenus,
  restaurantCourseQuestions,
  image,
  onCreate,
  onCreateError,
  onUpdate,
  onUpdateError,
  onArchive,
  sortedLocales,
  createTranslation,
  formatCurrencyInput,
}: RestaurantCourseFormProps) {
  const { width, sm } = useResponsive()
  const [isUpdating, setIsUpdating] = useFormState(false)
  const alertRef = useRef<AlertMethods>(null)
  const { data: restaurant } = useRestaurant(restaurantId)
  const isStandardOrHigher =
    restaurant?.reservation_book_plan_type === 'professional' ||
    restaurant?.reservation_book_plan_type === 'standard' ||
    restaurant?.reservation_book_plan_type === 'special'

  const defaultTitle = useMemo(
    () => ({
      ja: restaurantCourse?.title_i18n_ja ?? '',
      en: restaurantCourse?.title_i18n_en ?? '',
      ko: restaurantCourse?.title_i18n_ko ?? '',
      'zh-CN': restaurantCourse?.title_i18n_zh_cn ?? '',
      'zh-TW': restaurantCourse?.title_i18n_zh_tw ?? '',
      'zh-HK': restaurantCourse?.title_i18n_zh_hk ?? '',
      th: restaurantCourse?.title_i18n_th ?? '',
    }),
    [
      restaurantCourse?.title_i18n_en,
      restaurantCourse?.title_i18n_ja,
      restaurantCourse?.title_i18n_ko,
      restaurantCourse?.title_i18n_th,
      restaurantCourse?.title_i18n_zh_cn,
      restaurantCourse?.title_i18n_zh_hk,
      restaurantCourse?.title_i18n_zh_tw,
    ]
  )

  const [title, setTitle] = useFormState<LocaleValue>(defaultTitle)

  const defaultContent = useMemo(
    () => ({
      ja: restaurantCourse?.content_i18n_ja ?? '',
      en: restaurantCourse?.content_i18n_en ?? '',
      ko: restaurantCourse?.content_i18n_ko ?? '',
      'zh-CN': restaurantCourse?.content_i18n_zh_cn ?? '',
      'zh-TW': restaurantCourse?.content_i18n_zh_tw ?? '',
      'zh-HK': restaurantCourse?.content_i18n_zh_hk ?? '',
      th: restaurantCourse?.content_i18n_th ?? '',
    }),
    [
      restaurantCourse?.content_i18n_en,
      restaurantCourse?.content_i18n_ja,
      restaurantCourse?.content_i18n_ko,
      restaurantCourse?.content_i18n_th,
      restaurantCourse?.content_i18n_zh_cn,
      restaurantCourse?.content_i18n_zh_hk,
      restaurantCourse?.content_i18n_zh_tw,
    ]
  )

  const [content, setContent] = useFormState<LocaleValue>(defaultContent)
  const contentRef = useRef<RNTextInput>(null)

  const [price, setPrice] = useFormState(
    restaurantCourse?.price.toString() ?? ''
  )
  const [taxIncluded, setTaxIncluded] = useFormState(
    restaurantCourse?.tax_included ?? true
  )
  const [taxType, setTaxType] = useFormState(
    restaurantCourse?.tax_type ?? 'default'
  )
  const [selectedTableMenuIds, setSelectedTableMenuIds] = useFormState(
    restaurantCourse?.table_menus.map((tableMenu) => tableMenu.id) ?? []
  )
  const [questionIds, setQuestionIds] = useFormState<
    Array<RestaurantCourseModel['questions'][number]['id'] | null>
  >([
    ...(restaurantCourse?.questions?.map((question) => question.id) ?? []),
    null,
  ])
  const [selectedImage, setSelectedImage] = useFormState<string | null>(null)
  const [removeImage, setRemoveImage] = useFormState(false)

  const [cancelPolicies, setCancelPolicies] = useFormState<FormCancelPolicy[]>(
    restaurantCourse?.restaurant_course_cancel_policies?.map(
      (cancelPolicy) => ({
        id: cancelPolicy.id,
        cancelPercent: `${cancelPolicy.cancel_percent}`,
        cancelTime: cancelPolicy.cancel_time,
        Destroy: false,
      })
    ) ?? []
  )

  const [stayingTimeSec, setStayingTimeSec] = useFormState(
    restaurantCourse?.staying_time ?? null
  )
  const previousStayingTimeSec = useRef<number | null>(
    restaurantCourse?.staying_time ?? null
  )
  const [customerRegionRestrictionType, setCustomerRegionRestrictionType] =
    useFormState(
      restaurantCourse?.customer_region_restriction_type ?? 'no_restriction'
    )
  const [minPartySize, setMinPartySize] = useFormState(
    restaurantCourse?.min_party_size != null
      ? String(restaurantCourse.min_party_size)
      : null
  )
  const [maxPartySize, setMaxPartySize] = useFormState(
    restaurantCourse?.max_party_size != null
      ? String(restaurantCourse.max_party_size)
      : null
  )
  const [isPartySizeSettingEnabled, setIsPartySizeSettingEnabled] =
    useFormState(
      restaurantCourse?.max_party_size != null &&
        restaurantCourse?.min_party_size != null
    )
  const [stockSetting, setStockSetting] = useFormState<{
    id: string | null
    quantity: string | null
    stock_type: 'daily'
    _destroy: boolean
  } | null>(
    restaurantCourse?.restaurant_course_stock_setting != null
      ? {
          ...restaurantCourse.restaurant_course_stock_setting,
          quantity:
            restaurantCourse.restaurant_course_stock_setting.quantity.toString(),
          _destroy: false,
        }
      : null
  )

  const [
    restaurantCourseReservationPeriodPolicy,
    setRestaurantCourseReservationPeriodPolicy,
  ] = useFormState(
    restaurantCourse?.restaurant_course_reservation_period_policy ??
      INITIAL_RESTAURANT_COURSE_RESERVATION_PERIOD_POLICY
  )

  const onChangeQuestionId = (
    index: number,
    questionId: RestaurantCourseModel['questions'][number]['id'] | null
  ) => {
    setQuestionIds(
      produce(questionIds, (draft) => {
        draft[index] = questionId
      })
    )
  }

  const onDestroyQuestionId = (index: number) => {
    setQuestionIds(
      produce(questionIds, (draft) => {
        draft.splice(index, 1)
      })
    )
  }

  const addQuestionId = () => {
    setQuestionIds(
      produce(questionIds, (draft) => {
        draft.push(null)
      })
    )
  }

  const onChangeSelectedTableMenuIds = (
    tableMenuId: string,
    value: boolean
  ) => {
    if (value) {
      if (!selectedTableMenuIds.includes(tableMenuId)) {
        setSelectedTableMenuIds([...selectedTableMenuIds, tableMenuId])
      }
    } else {
      setSelectedTableMenuIds(
        selectedTableMenuIds.filter(
          (selectedTableMenuId) => selectedTableMenuId !== tableMenuId
        )
      )
    }
  }

  const onChangeCancelPolicyCancelTime = (
    index: number,
    value: RestaurantCourseModel['restaurant_course_cancel_policies'][number]['cancel_time']
  ) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft[index].cancelTime = value
      })
    )
  }

  const onChangeCancelPolicyCancelPercent = (index: number, value: string) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft[index].cancelPercent = value
      })
    )
  }

  const onDestroyCancelPolicy = (index: number) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        if (draft[index].id === undefined) {
          draft.splice(index, 1)
        } else {
          draft[index].Destroy = true
        }
      })
    )
  }

  const addCancelPolicyForm = () => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft.push({
          cancelPercent: '',
          cancelTime: 0,
          Destroy: false,
        })
      })
    )
  }

  const onCreate_ = async () => {
    setIsUpdating(true)
    const restaurantCourseCancelPolicies: CreateParams['restaurant_course_cancel_policies'] =
      cancelPolicies
        .filter(({ Destroy }) => !Destroy)
        .map(({ id, cancelTime, cancelPercent, Destroy }) => ({
          id: id ?? null,
          cancel_percent: Number(cancelPercent),
          cancel_amount: 0,
          cancel_time: cancelTime,
          policy_type: 'type_percent',
          _destroy: Destroy,
        }))

    const parsedPrice = parseFloat(price)
    const parsedMinPartySize =
      minPartySize != null ? Number(minPartySize) : null
    const parsedMaxPartySize =
      maxPartySize != null ? Number(maxPartySize) : null
    const stock: UpdateParams['restaurant_course_stock_setting'] =
      stockSetting != null
        ? {
            ...stockSetting,
            quantity: parseInt(stockSetting.quantity ?? '', 10),
            stock_type: getStockType(stockSetting.stock_type),
          }
        : null

    const { errors } = validate({
      cancelPolicies,
      price: parsedPrice,
      minPartySize: parsedMinPartySize,
      maxPartySize: parsedMaxPartySize,
      stockSetting: stock,
    })

    if (errors.length > 0) {
      setIsUpdating(false)
      return onCreateError(errors)
    }

    const params: CreateParams = {
      restaurant_id: restaurantId,
      title: title.ja,
      title_i18n_ja: title.ja,
      title_i18n_en: title.en,
      title_i18n_ko: title.ko,
      title_i18n_zh_cn: title['zh-CN'],
      title_i18n_zh_hk: title['zh-HK'],
      title_i18n_zh_tw: title['zh-TW'],
      title_i18n_th: title.th,
      content: content.ja,
      content_i18n_ja: content.ja,
      content_i18n_en: content.en,
      content_i18n_ko: content.ko,
      content_i18n_zh_cn: content['zh-CN'],
      content_i18n_zh_hk: content['zh-HK'],
      content_i18n_zh_tw: content['zh-TW'],
      content_i18n_th: content.th,
      price: parsedPrice,
      tax_included: taxIncluded,
      tax_type: taxType === 'default' ? 0 : 1,
      table_menu_ids: selectedTableMenuIds,
      questions: [],
      question_ids: questionIds.filter(
        (questionId): questionId is string => questionId != null
      ),
      restaurant_course_cancel_policies: restaurantCourseCancelPolicies,
      image: selectedImage ?? '',
      translation_mode: 'for_r_create',
      customer_region_restriction_type: customerRegionRestrictionType,
      restaurant_course_reservation_period_policy:
        restaurantCourseReservationPeriodPolicy,
      staying_time: null,
      max_party_size: parsedMaxPartySize,
      min_party_size: parsedMinPartySize,
      restaurant_course_stock_setting: stock,
    }
    return onCreate(params).finally(() => setIsUpdating(false))
  }

  const onUpdate_ = async () => {
    if (restaurantCourseId === undefined) return
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    setIsUpdating(true)

    const restaurantCourseCancelPolicies: UpdateParams['restaurant_course_cancel_policies'] =
      cancelPolicies.map(({ id, Destroy, cancelPercent, cancelTime }) => ({
        id: id ?? null,
        _destroy: Destroy,
        cancel_amount: 0,
        cancel_percent: Number(cancelPercent),
        policy_type: 'type_percent',
        cancel_time: cancelTime,
      }))
    const parsedPrice = parseFloat(price)
    const parsedMinPartySize =
      minPartySize != null ? parseNumber(minPartySize) : null
    const parsedMaxPartySize =
      maxPartySize != null ? parseNumber(maxPartySize) : null

    const stock: UpdateParams['restaurant_course_stock_setting'] =
      stockSetting != null
        ? {
            ...stockSetting,
            quantity: parseNumber(stockSetting.quantity ?? ''),
            stock_type: getStockType(stockSetting.stock_type),
          }
        : null

    const { errors } = validate({
      cancelPolicies,
      price: parsedPrice,
      minPartySize: parsedMinPartySize,
      maxPartySize: parsedMaxPartySize,
      stockSetting: stock,
    })

    if (errors.length > 0) {
      setIsUpdating(false)
      return onUpdateError(errors)
    }

    const params: UpdateParams = {
      restaurant_id: restaurantId,
      id: restaurantCourseId,
      title: title.ja,
      title_i18n_ja: title.ja,
      title_i18n_en: title.en,
      title_i18n_ko: title.ko,
      title_i18n_zh_cn: title['zh-CN'],
      title_i18n_zh_hk: title['zh-HK'],
      title_i18n_zh_tw: title['zh-TW'],
      title_i18n_th: title.th,
      content: content.ja,
      content_i18n_ja: content.ja,
      content_i18n_en: content.en,
      content_i18n_ko: content.ko,
      content_i18n_zh_cn: content['zh-CN'],
      content_i18n_zh_hk: content['zh-HK'],
      content_i18n_zh_tw: content['zh-TW'],
      content_i18n_th: content.th,
      price: parsedPrice,
      tax_included: taxIncluded,
      tax_type: taxType === 'default' ? 0 : 1,
      table_menu_ids: selectedTableMenuIds,
      questions: [],
      question_ids: questionIds.filter(
        (questionId): questionId is string => questionId != null
      ),
      restaurant_course_cancel_policies: restaurantCourseCancelPolicies,
      image: selectedImage ?? '',
      _destroy: removeImage,
      translation_mode: 'for_r_update',
      staying_time: stayingTimeSec,
      customer_region_restriction_type: customerRegionRestrictionType,
      price_str: restaurantCourse?.price_str ?? '',
      restaurant_course_reservation_period_policy:
        restaurantCourseReservationPeriodPolicy,
      max_party_size: parsedMaxPartySize,
      min_party_size: parsedMinPartySize,
      restaurant_course_stock_setting: stock,
    }

    if (
      hasIncompleteChanges(title, defaultTitle) ||
      hasIncompleteChanges(content, defaultContent)
    ) {
      if (!(await confirmSaveIncompleteChangesAsync(alert))) {
        setIsUpdating(false)
        return
      }
    }

    return onUpdate(params).finally(() => setIsUpdating(false))
  }

  return (
    <View
      style={{
        flex: 1,
        paddingBottom: 106,
        backgroundColor: Platform.OS === 'web' ? Colors.bgBlack : 'transparent',
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={
          width < sm
            ? {
                paddingVertical: 24,
                paddingHorizontal: 16,
              }
            : {
                paddingVertical: 48,
                paddingHorizontal: 32,
              }
        }
      >
        <Text style={{ fontSize: 14, color: Colors.black60 }}>
          {t(
            '予約サービスAutoReserveで使用します。セルフオーダーには反映されません。\n更新内容は、まだ予約されていないコースのみ適用されます。予約済みのコースには反映されません。'
          )}
        </Text>
        <View>
          <Text style={{ marginTop: 16, fontSize: 22, fontWeight: '600' }}>
            {t('基本情報')}
          </Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <TranslationFormList
              formLabel={<FormLabel value={t('タイトル')} required />}
              showsFormDescription
              formDescriptionText={t(
                '日本語の情報を入力すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。'
              )}
              sortedLocales={sortedLocales}
              values={title}
              onChangeValues={setTitle}
              createTranslation={(params) => createTranslation(params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={title[locale]}
                    onChangeText={(text) =>
                      setTitle((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <TranslationFormList
              formLabel={<FormLabel value={t('説明')} />}
              showsFormDescription
              sortedLocales={sortedLocales}
              formDescriptionText={t(
                '日本語の情報を入力すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。'
              )}
              values={content}
              onChangeValues={setContent}
              createTranslation={(params) => createTranslation(params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    ref={contentRef}
                    multiline
                    scrollEnabled={false}
                    placeholder={''}
                    value={content[locale]}
                    onChangeText={(text) =>
                      setContent((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider style={{ marginVertical: 24 }} />
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('画像')} />}
              style={{ marginTop: formSpace }}
            >
              <View
                style={[
                  { alignSelf: 'flex-start', flex: 1 },
                  width < sm && { marginTop: 16 },
                ]}
              >
                <View>
                  {(image && !removeImage) ?? selectedImage != null ? (
                    <View style={{ width: 120, height: 120 }}>
                      <SelectedImage
                        source={{
                          uri: selectedImage ?? image?.sm,
                        }}
                        onRemovePress={() => {
                          setSelectedImage(null)
                          setRemoveImage(true)
                        }}
                      />
                    </View>
                  ) : (
                    <SelectImage
                      onSelectImage={(image) => {
                        setSelectedImage(image)
                        setRemoveImage(false)
                      }}
                    />
                  )}
                </View>
              </View>
            </FormGroup>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('メニュー')} />}
              style={{ marginTop: formSpace }}
            >
              <Wrap verticalGap={16} horizontalGap={16}>
                {tableMenus != null &&
                  tableMenus.map((tableMenu) => {
                    return (
                      <View key={tableMenu.id}>
                        <Checkbox
                          checked={selectedTableMenuIds.includes(tableMenu.id)}
                          onChange={(value) =>
                            onChangeSelectedTableMenuIds(tableMenu.id, value)
                          }
                          checkboxLabel={
                            <CheckboxLabel value={tableMenu.name} />
                          }
                        />
                      </View>
                    )
                  })}
              </Wrap>
            </FormGroup>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 48 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('価格')}</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('料金')} required />}
              style={{ marginTop: width < sm ? 0 : formSpace }}
            >
              <TextInput
                keyboardType="numeric"
                onChangeText={(text) =>
                  setPrice(
                    formatCurrencyInput ? formatCurrencyInput(text) : text
                  )
                }
                value={
                  formatCurrencyInput ? formatCurrencyInput(price) : `${price}`
                }
              />
            </FormGroup>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('税込・税抜設定')} required />}
              style={{ marginTop: formSpace }}
            >
              <RadioGroup
                value={taxIncluded}
                onChange={(v) => setTaxIncluded(v)}
                style={{
                  flexDirection: width < sm ? 'column' : 'row',
                  gap: 16,
                }}
                radioContainerStyle={[
                  {
                    marginLeft: 0,
                  },
                  width >= sm && { flex: 1 },
                ]}
              >
                <Radio value radioLabel={<Text>{t('税込')}</Text>} />
                <Radio value={false} radioLabel={<Text>{t('税抜')}</Text>} />
              </RadioGroup>
            </FormGroup>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('税種別')} required />}
              style={{ marginTop: formSpace }}
            >
              <RadioGroup
                value={taxType}
                onChange={(v) => setTaxType(v)}
                style={{
                  flexDirection: width < sm ? 'column' : 'row',
                  gap: 16,
                }}
                radioContainerStyle={[
                  {
                    marginLeft: 0,
                  },
                  width >= sm && { flex: 1 },
                ]}
              >
                <Radio
                  value={'default'}
                  radioLabel={<Text>{t('標準税率(10%)')}</Text>}
                />
                <Radio
                  value={'reduced'}
                  radioLabel={<Text>{t('軽減税率(8%)')}</Text>}
                />
              </RadioGroup>
            </FormGroup>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 48 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('予約')}</Text>
          <ShadowBox style={{ marginTop: 24, padding: 24 }}>
            <Text
              style={{
                fontSize: width >= sm ? 18 : 14,
                fontWeight: '600',
                lineHeight: 27,
              }}
            >
              {t('予約受付ルール')}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '300',
                lineHeight: 21,
                marginBottom: 24,
              }}
            >
              {t(
                '予約可能時間帯/予約受付開始/予約受付終了/お客様の滞在時間を個別に設定しない場合は、営業時間の設定が反映されます。'
              )}
            </Text>
            <View
              style={{
                borderBottomWidth: 0.5,
                borderBottomColor: Colors.border,
                rowGap: 24,
                paddingBottom: 24,
                marginBottom: 24,
              }}
            >
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline-expanded'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      paddingVertical: width < sm ? undefined : 16,
                      fontSize: width >= sm ? 18 : 14,
                    }}
                    value={t('予約受付終了')}
                  />
                }
              >
                <RadioGroup
                  mode={width < sm ? 'vertical' : 'inline'}
                  value={restaurantCourseReservationPeriodPolicy?.until_type}
                  radioContainerStyle={{ marginTop: 0 }}
                  onChange={(value) => {
                    setRestaurantCourseReservationPeriodPolicy({
                      ...restaurantCourseReservationPeriodPolicy,
                      until_type: value,
                    })
                  }}
                >
                  {[
                    {
                      name: t('営業時間に従う'),
                      value: UNTIL_TYPE.business_time_based,
                    },
                    {
                      name: t('個別に設定（〜前）'),
                      value: UNTIL_TYPE.offset,
                    },
                    {
                      name: t('個別に設定(〜日前〜時)'),
                      value: UNTIL_TYPE.specific,
                    },
                  ].map((param, index) => (
                    <Radio
                      style={{ flex: 1 }}
                      key={`${index}`}
                      value={param.value}
                      radioLabel={
                        <RadioLabel
                          style={{
                            paddingVertical: width < sm ? 12 : 16,
                          }}
                          value={param.name}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
                <View
                  style={{
                    flexDirection: 'row',
                    columnGap: width < sm ? 8 : 4,
                    alignItems: 'center',
                    marginTop: 8,
                    display:
                      restaurantCourseReservationPeriodPolicy?.until_type !==
                      'business_time_based'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <SelectInput
                    style={{ height: 64 }}
                    disabled={
                      restaurantCourseReservationPeriodPolicy?.until_type ===
                      UNTIL_TYPE.business_time_based
                    }
                    selectedValue={
                      restaurantCourseReservationPeriodPolicy.until_type ===
                      UNTIL_TYPE.business_time_based
                        ? null
                        : restaurantCourseReservationPeriodPolicy.until_day_before
                    }
                    setValue={(value) => {
                      if (value == null) return
                      setRestaurantCourseReservationPeriodPolicy({
                        ...restaurantCourseReservationPeriodPolicy,
                        until_day_before: value,
                      })
                    }}
                    items={UNTIL_DAY_BEFORE_ITEMS}
                  />
                  <Text style={{ fontSize: 18, fontWeight: '300' }}>:</Text>
                  <SelectInput
                    style={{ height: 64 }}
                    disabled={
                      restaurantCourseReservationPeriodPolicy.until_type ===
                      UNTIL_TYPE.business_time_based
                    }
                    selectedValue={
                      restaurantCourseReservationPeriodPolicy.until_type ===
                      UNTIL_TYPE.business_time_based
                        ? null
                        : restaurantCourseReservationPeriodPolicy.until_type ===
                            UNTIL_TYPE.offset
                          ? restaurantCourseReservationPeriodPolicy.until_time_before
                          : restaurantCourseReservationPeriodPolicy.until_specific_time
                    }
                    setValue={(value) => {
                      if (value == null) return
                      if (
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.business_time_based
                      )
                        return
                      if (
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.offset
                      ) {
                        setRestaurantCourseReservationPeriodPolicy({
                          ...restaurantCourseReservationPeriodPolicy,
                          until_time_before: value,
                        })
                      } else {
                        setRestaurantCourseReservationPeriodPolicy({
                          ...restaurantCourseReservationPeriodPolicy,
                          until_specific_time: value,
                        })
                      }
                    }}
                    items={
                      restaurantCourseReservationPeriodPolicy.until_type ===
                      UNTIL_TYPE.offset
                        ? UNTIL_TIME_BEFORE_ITEMS
                        : UNTIL_SPECIFIC_TIME_ITEMS
                    }
                  />
                </View>
                {/* NOTE: https://github.com/hello-ai/auto_reserve_front/pull/5143#issuecomment-2294857206 の対応後にコメントアウトを外す */}
                {/* <Checkbox
                  style={{
                    marginTop: 24,
                    backgroundColor: Colors.bgBlack,
                    paddingVertical: 20,
                    paddingHorizontal: 16,
                  }}
                  onChange={(value) => {
                    setRestaurantCourseReservationPeriodPolicy({
                      ...restaurantCourseReservationPeriodPolicy,
                      until_enabled: value,
                    })
                  }}
                  checked={
                    restaurantCourseReservationPeriodPolicy.until_enabled
                  }
                  checkboxLabel={
                    <CheckboxLabel
                      value={t('予約受付終了後の変更を不可にする')}
                    />
                  }
                /> */}
              </FormGroup>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline-expanded'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      paddingVertical: width < sm ? undefined : 16,
                    }}
                    value={t('お客様の滞在時間')}
                  />
                }
              >
                <RadioGroup
                  mode={width < sm ? 'vertical' : 'inline'}
                  value={stayingTimeSec == null ? 'default' : 'custom'}
                  radioContainerStyle={{ marginTop: 0 }}
                  onChange={(value) => {
                    if (value === 'default') {
                      previousStayingTimeSec.current = stayingTimeSec
                      setStayingTimeSec(null)
                    } else {
                      if (previousStayingTimeSec.current != null) {
                        setStayingTimeSec(previousStayingTimeSec.current)
                        return
                      }
                      setStayingTimeSec(0)
                    }
                  }}
                >
                  {[
                    { name: t('営業時間に従う'), value: 'default' },
                    { name: t('個別に設定'), value: 'custom' },
                  ].map((param, index) => (
                    <Radio
                      style={{ flex: 1 }}
                      key={`${index}`}
                      value={param.value}
                      radioLabel={
                        <RadioLabel
                          style={{
                            paddingVertical: width < sm ? 12 : 16,
                          }}
                          value={param.name}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
                {stayingTimeSec != null && (
                  <View
                    style={{
                      flexDirection: 'row',
                      columnGap: width < sm ? 8 : 4,
                      alignItems: 'center',
                      marginTop: 8,
                    }}
                  >
                    <SelectInput
                      style={{ height: 64 }}
                      selectedValue={parseSeconds(stayingTimeSec).hour}
                      setValue={(value) => {
                        setStayingTimeSec((prev) => {
                          const { minute } = parseSeconds(prev ?? 0)
                          return value * 3600 + minute * 60
                        })
                      }}
                      items={Array.from({ length: 24 }).map((_, index) => {
                        const hour = index
                        return {
                          label: t('{{hour}}時間', {
                            hour,
                          }),
                          value: hour,
                        }
                      })}
                    />
                    <Text style={{ fontSize: 18, fontWeight: '300' }}>:</Text>
                    <SelectInput
                      style={{ height: 64 }}
                      selectedValue={parseSeconds(stayingTimeSec).minute}
                      setValue={(value) => {
                        setStayingTimeSec((prev) => {
                          const { hour } = parseSeconds(prev ?? 0)
                          return hour * 3600 + value * 60
                        })
                      }}
                      items={Array.from({ length: 4 }).map((_, coefficient) => {
                        const minute = coefficient * 15
                        return {
                          label: t('{{minute}}分', {
                            minute,
                          }),
                          value: minute,
                        }
                      })}
                    />
                  </View>
                )}
              </FormGroup>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline-expanded'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      paddingVertical: width < sm ? undefined : 16,
                    }}
                    value={t('利用可能人数')}
                  />
                }
              >
                <RadioGroup
                  mode={width < sm ? 'vertical' : 'inline'}
                  value={isPartySizeSettingEnabled}
                  radioContainerStyle={{ marginTop: 0 }}
                  onChange={(value) => {
                    setIsPartySizeSettingEnabled(value)
                    if (value === false) {
                      setMinPartySize(null)
                      setMaxPartySize(null)
                    }
                  }}
                >
                  {[
                    { name: t('設定しない'), value: false },
                    { name: t('設定する'), value: true },
                  ].map((param, index) => (
                    <Radio
                      style={{ flex: 1 }}
                      key={`${index}`}
                      value={param.value}
                      radioLabel={
                        <RadioLabel
                          style={{
                            paddingVertical: width < sm ? 12 : 16,
                          }}
                          value={param.name}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
                {isPartySizeSettingEnabled && (
                  <View
                    style={{
                      flexDirection: 'row',
                      columnGap: width < sm ? 8 : 4,
                      alignItems: 'center',
                      marginTop: 8,
                    }}
                  >
                    <TextInput
                      style={{ height: 64, flex: 1 }}
                      value={`${minPartySize ?? ''}`}
                      keyboardType="number-pad"
                      onChangeText={(value) => {
                        setMinPartySize(value)
                      }}
                    />
                    <Text style={{ fontSize: 18, fontWeight: '300' }}>〜</Text>
                    <TextInput
                      style={{ height: 64, flex: 1 }}
                      value={`${maxPartySize ?? ''}`}
                      keyboardType="number-pad"
                      onChangeText={(value) => {
                        setMaxPartySize(value)
                      }}
                    />
                  </View>
                )}
              </FormGroup>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline-expanded'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      paddingVertical: width < sm ? undefined : 16,
                    }}
                    value={t('在庫設定')}
                  />
                }
              >
                <RadioGroup
                  mode={width < sm ? 'vertical' : 'inline'}
                  value={stockSetting != null && !stockSetting._destroy}
                  radioContainerStyle={{ marginTop: 0 }}
                  onChange={(value) => {
                    if (value) {
                      if (stockSetting == null || stockSetting.id == null) {
                        // 未設定から設定する場合
                        setStockSetting({
                          id: null,
                          quantity: '',
                          stock_type: 'daily',
                          _destroy: false,
                        })
                      } else {
                        // 削除済みの在庫設定を復元する場合
                        setStockSetting({
                          ...stockSetting,
                          _destroy: false,
                        })
                      }
                    } else {
                      if (stockSetting == null) return
                      if (stockSetting.id == null) {
                        // 未設定の在庫設定を削除する場合
                        setStockSetting(null)
                      } else {
                        // 設定済みの在庫設定を削除する場合
                        setStockSetting({
                          ...stockSetting,
                          _destroy: true,
                        })
                      }
                    }
                  }}
                >
                  {[
                    { name: t('設定しない'), value: false },
                    { name: t('設定する'), value: true },
                  ].map((param, index) => (
                    <Radio
                      style={{ flex: 1 }}
                      key={`${index}`}
                      value={param.value}
                      radioLabel={
                        <RadioLabel
                          style={{
                            paddingVertical: width < sm ? 12 : 16,
                          }}
                          value={param.name}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
                {stockSetting != null && !stockSetting._destroy && (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        columnGap: width < sm ? 8 : 4,
                        alignItems: 'center',
                        marginTop: 8,
                      }}
                    >
                      <TextInput
                        style={{ height: 64, flex: 1, maxWidth: 300 }}
                        value={`${stockSetting.quantity ?? ''}`}
                        keyboardType="number-pad"
                        onChangeText={(value) => {
                          setStockSetting({
                            ...stockSetting,
                            quantity: value,
                          })
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        marginTop: 8,
                        color: Colors.black60,
                        fontSize: 14,
                      }}
                    >
                      {t('1日あたりで予約できる客数を設定してください')}
                    </Text>
                  </>
                )}
              </FormGroup>
            </View>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline-expanded'}
              formLabel={
                <View
                  style={{
                    marginTop: (60 - 18 * lineHeightMultiplier) / 2,
                  }}
                >
                  <FormLabel value={t('お客様への質問', {})} />
                </View>
              }
              style={{ marginTop: width < sm ? 0 : formSpace }}
            >
              {questionIds.map((questionId, index) => {
                return (
                  <View
                    key={`${index}`}
                    style={{
                      marginTop: index === 0 ? 0 : 24,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SelectInput<
                      RestaurantCourseModel['questions'][number]['id'] | null
                    >
                      selectedValue={questionId}
                      setValue={(value) => {
                        onChangeQuestionId(index, value)
                      }}
                      items={[
                        {
                          label: t('質問タイトル'),
                          value: null,
                        },
                        ...(restaurantCourseQuestions?.map((question) => {
                          return {
                            label: question.name ?? '',
                            value: question.id,
                          }
                        }) ?? []),
                      ]}
                      style={{
                        height: 64,
                      }}
                      labelStyle={{
                        color:
                          questionId == null ? Colors.black60 : Colors.black,
                      }}
                    />
                    <TouchableOpacity
                      onPress={() => {
                        onDestroyQuestionId(index)
                      }}
                      style={{
                        marginLeft: 16,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        size={21}
                        color={Colors.black60}
                        style={{
                          width: 24,
                          height: 24,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                )
              })}
            </FormGroup>

            <LegacyButton
              textStyle={{
                color: Colors.primary,
                fontWeight: 'normal',
              }}
              buttonStyle={{
                marginTop: formSpace,
                height: 52,
                borderColor: Colors.primary,
                backgroundColor: 'white',
              }}
              onPress={() => addQuestionId()}
              text={t('質問を追加する')}
            />
            {cancelPolicies.map((cancelPolicy, index) => {
              if (cancelPolicy.Destroy) return null
              return (
                <FormGroup
                  key={`${index}`}
                  mode={width < sm ? 'vertical' : 'inline'}
                  formLabel={
                    <FormLabel
                      value={t('キャンセル料 {{text}}', {
                        text: index + 1,
                      })}
                    />
                  }
                  style={{
                    marginTop: 32,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={[
                        { flex: 1 },
                        width >= sm && {
                          flexDirection: 'row',
                          alignItems: 'center',
                        },
                      ]}
                    >
                      <SelectInput
                        style={{
                          flex: 1,
                          height: 64,
                        }}
                        containerStyle={{
                          height: 64,
                        }}
                        items={cancelPolicyTimeItems}
                        selectedValue={cancelPolicy.cancelTime}
                        setValue={(value) => {
                          onChangeCancelPolicyCancelTime(index, value)
                        }}
                        labelStyle={{
                          color:
                            cancelPolicy.cancelTime === 0
                              ? Colors.disabledBlack
                              : Colors.black,
                        }}
                      />
                      <View
                        style={{
                          flex: 1,
                          marginTop: width < sm ? 16 : 0,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <TextInput
                          style={{
                            marginLeft: width < sm ? 0 : 16,
                            flex: 1,
                            height: 64,
                          }}
                          autoCapitalize="none"
                          keyboardType="number-pad"
                          placeholder=""
                          value={cancelPolicy.cancelPercent}
                          onChangeText={(value) => {
                            onChangeCancelPolicyCancelPercent(index, value)
                          }}
                        />
                        {width < sm && <Text style={{ marginLeft: 8 }}>%</Text>}
                      </View>
                      {width >= sm && <Text style={{ marginLeft: 8 }}>%</Text>}
                    </View>

                    <TouchableOpacity
                      style={{ marginLeft: 32 }}
                      onPress={() => onDestroyCancelPolicy(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        size={24}
                        color={Colors.secondaryBlack}
                      />
                    </TouchableOpacity>
                  </View>
                </FormGroup>
              )
            })}

            <LegacyButton
              textStyle={{
                color: Colors.primary,
                fontWeight: 'normal',
              }}
              buttonStyle={{
                marginTop: 24,
                height: 52,
                borderColor: Colors.primary,
                backgroundColor: 'white',
              }}
              onPress={() => addCancelPolicyForm()}
              text={t('キャンセル料を追加する')}
            />
          </ShadowBox>
        </View>
        <View style={{ marginTop: 48 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('その他')}</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <View
              style={{
                alignItems:
                  width < sm || !isStandardOrHigher ? 'flex-start' : 'center',
                gap: 24,
                flexDirection:
                  width < sm || !isStandardOrHigher ? 'column' : 'row',
              }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14 }}>
                {t('提供可能なお客様の設定')}
              </Text>
              {isStandardOrHigher ? (
                <View>
                  <RadioGroup
                    value={customerRegionRestrictionType}
                    onChange={(v) => setCustomerRegionRestrictionType(v)}
                    style={{
                      flexDirection: width < sm ? 'column' : 'row',
                      gap: 16,
                    }}
                    radioContainerStyle={{
                      marginLeft: 0,
                    }}
                  >
                    <Radio
                      radioLabel={
                        <Text style={{ fontSize: 14 }}>{t('すべて')}</Text>
                      }
                      value="no_restriction"
                    />
                    <Radio
                      radioLabel={
                        <Text style={{ fontSize: 14 }}>{t('国内(日本)')}</Text>
                      }
                      value="domestic_only"
                    />
                    <Radio
                      radioLabel={
                        <Text style={{ fontSize: 14 }}>{t('海外')}</Text>
                      }
                      value="overseas_only"
                    />
                  </RadioGroup>
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: width < sm ? 'column' : 'row',
                    padding: 16,
                    backgroundColor: Colors.white,
                    borderRadius: 8,
                    borderColor: Colors.primary,
                    borderWidth: 1,
                    gap: 12,
                    alignItems: width < sm ? 'flex-end' : 'center',
                  }}
                >
                  <View
                    style={{
                      flex: width < sm ? undefined : 1,
                      flexDirection: 'row',
                      gap: width < sm ? 8 : 12,
                      alignItems: width < sm ? 'flex-start' : 'center',
                    }}
                  >
                    <View style={{ padding: 6 }}>
                      <Image
                        style={{ height: 16, width: 16 }}
                        source={require('../../assets/images/plan-icon.png')}
                      />
                    </View>
                    <Text style={{ flex: 1, color: Colors.black }}>
                      {restaurant?.reservation_book_plan_type === 'entry'
                        ? t(
                            'リクエスト予約の場合、コースを提供するお客様の国を指定する機能（国内のみ、海外のみ、など）は、ご利用はできません。ご利用希望の方はお問い合わせください'
                          )
                        : t(
                            'コースを提供するお客様の国を指定する機能（国内のみ、海外のみ、など）は、スタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
                          )}
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      gap: 4,
                      alignItems: 'center',
                    }}
                    onPress={() =>
                      Linking.openURL(
                        'https://autoreserve.com/for_restaurants/contact'
                      )
                    }
                  >
                    <Text style={{ color: Colors.primary }}>
                      {t('お問い合わせへ')}
                    </Text>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color={Colors.primary}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {isStandardOrHigher &&
              customerRegionRestrictionType !== 'no_restriction' && (
                <View
                  style={{
                    flexDirection: width < sm ? 'column' : 'row',
                    borderRadius: width < sm ? 4 : 0,
                    paddingHorizontal: width < sm ? 16 : 20,
                    paddingVertical: 16,
                    backgroundColor: '#F4F4F4',
                    gap: 24,
                    alignItems: width < sm ? 'stretch' : 'center',
                    marginTop: 16,
                  }}
                >
                  <Text style={{ flex: 1 }}>
                    {t(
                      '外国人の方向けに特別な料金などを設定する場合は、コースの説明欄に記載いただくことを推奨します。（記載例：外国の方にも質の高いサービスをご提供するため、外国の方には特別料金を設定させていただいております）'
                    )}
                  </Text>
                  <Button
                    mode="outline"
                    variant="primary"
                    onPress={() => {
                      contentRef.current?.focus()
                    }}
                  >
                    {t('説明を編集する')}
                  </Button>
                </View>
              )}
          </ShadowBox>
        </View>
      </KeyboardAwareScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            paddingVertical: width < sm ? 16 : 12,
            paddingHorizontal: width < sm ? 16 : 24,
            paddingBottom: width < sm ? 40 : 58,
            backgroundColor: Colors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            gap: width > sm ? 16 : 12,
            borderTopWidth: 1,
            borderColor: Colors.black10,
          },
        ]}
      >
        {restaurantCourseId != null && (
          <LegacyButton
            buttonStyle={{
              borderColor: Colors.caution,
              backgroundColor: 'transparent',
              maxWidth: width >= sm ? 296 : '',
              height: 48,
              paddingLeft: width >= sm ? 0 : 12,
              paddingRight: width >= sm ? 0 : 4,
              flex: width >= sm ? 1 : undefined,
            }}
            textStyle={{
              color: Colors.caution,
              fontWeight: 'normal',
            }}
            onPress={onArchive}
            text={width > sm ? t('非表示にする') : t('')}
            icon={
              width < sm ? (
                <FontAwesomeIcon
                  icon={faArchive}
                  size={20}
                  color={Colors.caution}
                />
              ) : (
                ''
              )
            }
          />
        )}
        <Button
          loading={isUpdating}
          disabled={isUpdating}
          style={[
            {
              height: 48,
              maxWidth: 296,
              flex: 1,
            },
          ]}
          onPress={restaurantCourseId !== undefined ? onUpdate_ : onCreate_}
        >
          {restaurantCourseId != null ? t('更新する') : t('作成する')}
        </Button>
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </View>
  )
}
