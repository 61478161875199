import React, { useCallback, useRef, useState } from 'react'
import {
  Dimensions,
  Modal,
  Pressable,
  View,
  Text,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import {
  ALL_RESTAURANT_RESERVATION_VISIT_STATUS,
  RestaurantReservationVisitStatus,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { ReservationData } from './Content'
import { ReservationStatus } from '@hello-ai/ar_shared/src/types/ForR/Reservation'

export type PopoverAlignType = 'left' | 'right'
export type VisitStatusChipProps = {
  visitStatus: NonNullable<ReservationData['visit_status']>
  reservationStatus: ReservationData['status']
  alignPopover: PopoverAlignType
  style?: StyleProp<ViewStyle>
  onUpdateVisitStatus?: (
    newVisitStatus: ReservationData['visit_status']
  ) => Promise<void>
}

export function VisitStatusTag({
  visitStatus,
  reservationStatus,
  alignPopover,
  style,
  onUpdateVisitStatus,
}: VisitStatusChipProps) {
  const componentProps = calculateVisitStatusComponentProps(
    visitStatus,
    reservationStatus
  )
  const { width, sm } = useResponsive()
  const ref = useRef<View>(null)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [coordinates, setCoordinates] = useState<{
    x: number
    y: number
  } | null>(null)

  const [tagDimensions, setTagDimensions] = useState<{
    width: number
    height: number
  }>({
    width: 0,
    height: 0,
  })
  const [tooltipDimensions, setTooltipDimensions] = useState<{
    width: number
    height: number
  }>({
    // 初回は正確な値が取れないので、初期値を設定しておく
    width: width < sm ? 120 : 220,
    height: 160,
  })

  const { backgroundColor, textColor, visitStatusLabel } = componentProps

  const measure = useCallback(() => {
    return new Promise<void>((resolve) => {
      ref.current?.measure((x, y, width, height, pageX, pageY) => {
        setCoordinates(
          getCoordinates({
            x: pageX,
            y: pageY + height,
            width: tooltipDimensions.width,
            height: tooltipDimensions.height,
            xOffset:
              alignPopover === 'left'
                ? undefined
                : -(tooltipDimensions.width - tagDimensions.width),
          })
        )
        resolve()
      })
    })
  }, [
    tooltipDimensions.height,
    tooltipDimensions.width,
    tagDimensions.width,
    alignPopover,
  ])

  if (componentProps.cancelled) {
    return null
  }

  const newVisitStatusTags = getNewVisitStatusCandidates()
    .filter((item) => item.visitStatus !== visitStatus)
    .map((item) => (
      <Tag
        key={item.visitStatus}
        onPress={async () => {
          if (onUpdateVisitStatus) {
            await onUpdateVisitStatus(item.visitStatus)
          }
          setIsTooltipVisible(false)
        }}
        visitStatusLabel={item.visitStatusLabel}
        backgroundColor={item.backgroundColor}
        textColor={item.textColor}
      />
    ))

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
        },
        style,
      ]}
      ref={ref}
    >
      <View
        onLayout={(event) => {
          setTagDimensions({
            width: event.nativeEvent.layout.width,
            height: event.nativeEvent.layout.height,
          })
        }}
      >
        <Tag
          visitStatusLabel={visitStatusLabel}
          textColor={textColor}
          backgroundColor={backgroundColor}
          onPress={async () => {
            await measure()
            setIsTooltipVisible(true)
          }}
        />
      </View>
      <Modal
        visible={isTooltipVisible}
        transparent
        onRequestClose={() => {
          setIsTooltipVisible(false)
        }}
      >
        <Pressable
          onPress={() => {
            setIsTooltipVisible(false)
          }}
          style={{ flex: 1 }}
        >
          <View
            style={[
              {
                position: 'absolute',
              },
              coordinates != null && {
                top: coordinates.y,
                left: coordinates.x,
              },
              coordinates == null && {
                display: 'none',
              },
            ]}
          >
            <View
              onLayout={(event) => {
                setTooltipDimensions({
                  width:
                    width < sm
                      ? event.nativeEvent.layout.width - 100
                      : event.nativeEvent.layout.width,
                  height: event.nativeEvent.layout.height,
                })
              }}
              style={{
                backgroundColor: 'white',
              }}
            >
              {
                <View
                  style={{
                    padding: 10,
                    borderColor: Colors.border,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 4,
                    elevation: 1,
                  }}
                >
                  {/* 変更先の来店ステータスは4個のみ。合計6個のうち、今のステータスには変更できず、no_showはキャンセル扱いだから、6 - 4 = 2 */}
                  {/* そのため、4個固定で、2列 * 2行で表示する */}
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View style={{ margin: 5 }}>{newVisitStatusTags[0]}</View>
                    <View style={{ margin: 5 }}>{newVisitStatusTags[1]}</View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View style={{ margin: 5 }}>{newVisitStatusTags[2]}</View>
                    <View style={{ margin: 5 }}>{newVisitStatusTags[3]}</View>
                  </View>
                </View>
              }
            </View>
          </View>
        </Pressable>
      </Modal>
    </View>
  )
}

type TagProps = {
  onPress: () => void
  visitStatusLabel: string
  backgroundColor: string
  textColor: string
  disabled?: boolean
}

const getNewVisitStatusCandidates = () =>
  ALL_RESTAURANT_RESERVATION_VISIT_STATUS.map((item) => ({
    visitStatus: item,
    ...calculateVisitStatusComponentProps(item),
  })).filter((item) => !item.cancelled)

function Tag({
  onPress,
  visitStatusLabel,
  backgroundColor,
  textColor,
  disabled,
}: TagProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={{
        backgroundColor,
        borderRadius: 4,
        width: 90,
        paddingVertical: 10,
        height: 36,
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          fontSize: 14,
          fontWeight: '300',
          color: textColor,
          lineHeight: 18,
        }}
      >
        {visitStatusLabel}
      </Text>
    </TouchableOpacity>
  )
}

function getCoordinates({
  x,
  y,
  width,
  height,
  xOffset = 0,
}: {
  x: number
  y: number
  width: number
  height: number
  xOffset?: number
}) {
  const coordinatePadding = 10
  const dimensions = Dimensions.get('window')

  return {
    x: Math.min(
      Math.max(x, coordinatePadding) + xOffset,
      dimensions.width - width - coordinatePadding
    ),
    y:
      Math.min(
        Math.max(y, coordinatePadding),
        dimensions.height - height - coordinatePadding
      ) + 8,
  }
}

// ForRestaurantsNative:typecheckは、type guardを考慮していない模様
// そのため、cancelled: true側にもvisitStatusLabelのプロパティを明示的に宣言しないと
// typecheckでTS2339: Property 'visitStatusLabel' does not exist on type が発生する
export type VisitStatusComponentProps =
  | {
      visitStatusLabel: string
      backgroundColor: string
      textColor: string
      cancelled: false
    }
  | {
      visitStatusLabel: never
      backgroundColor: string
      textColor: string
      cancelled: true
    }

export function calculateVisitStatusComponentProps(
  visitStatus: RestaurantReservationVisitStatus,
  reservationStatus?: ReservationStatus
): VisitStatusComponentProps {
  if (visitStatus === 'no_show' || reservationStatus === 'canceled') {
    return {
      visitStatusLabel: '' as never,
      backgroundColor: Colors.caution,
      textColor: 'white',
      cancelled: true,
    }
  }

  switch (visitStatus) {
    case 'reserved':
      return {
        visitStatusLabel: t('予約完了'),
        backgroundColor: Colors.success,
        textColor: 'white',
        cancelled: false,
      }
    case 'some_visited':
      return {
        visitStatusLabel: t('一部来店'),
        backgroundColor: Colors.blue,
        textColor: 'white',
        cancelled: false,
      }
    case 'all_visited':
    case 'eaten':
      return {
        visitStatusLabel: t('来店済'),
        backgroundColor: Colors.accent40,
        textColor: Colors.black,
        cancelled: false,
      }
    case 'paid':
      return {
        visitStatusLabel: t('お会計済'),
        backgroundColor: Colors.primary,
        textColor: 'white',
        cancelled: false,
      }
    case 'departed':
      return {
        visitStatusLabel: t('退店済み'),
        backgroundColor: Colors.primary40,
        textColor: Colors.black,
        cancelled: false,
      }
  }
}
