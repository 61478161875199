import { isEmpty, inRange } from 'lodash'
import React, {
  useCallback,
  useMemo,
  useState as useFormState,
  useState,
  useEffect,
  useRef,
} from 'react'
import { View, ScrollView, Platform } from 'react-native'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import {
  ReservationStepper,
  ReservationStepperNextButton,
  ReservationStepperSkipButton,
} from '@hello-ai/ar_shared/src/components/ReservationStepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { CalendarModal } from '../Calendar'

import QuantityButton from '../../Shared/QuantityButton'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import SelectDateTimeInput from '../../Shared/SelectDateTimeInput'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'
import { ReservationFormState, ReservationFormStepProps } from './types'
import { getFormatTime, toSeconds } from '../../../modules/time'
import {
  RestaurantBusinessTime as RestaurantBusinessTimeModel,
  useRestaurantBusinessTimesByDate,
} from '../../../models/RestaurantBusinessTime'
import {
  DateQuestionTitle,
  DateTimeQuestionTitle,
  PartySizeQuestionTitle,
} from '../FormCommon/Title'
import { displayToastError } from '../../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faDeleteLeft } from '@fortawesome/pro-solid-svg-icons/faDeleteLeft'

function PartySizeInput({
  partySize,
  minPartySize = 0,
  maxPartySize = 100,
  onChangePartySize,
}: {
  partySize: string
  minPartySize?: number
  maxPartySize?: number
  onChangePartySize: (value: string) => void
}) {
  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        },
      ]}
    >
      <QuantityButton
        disabled={Number(partySize || '0') <= minPartySize}
        icon={faMinus}
        onPress={() => {
          const newPartySize = Number(partySize || '0') - 1
          if (newPartySize < minPartySize) {
            onChangePartySize(`${minPartySize}`)
          }
          onChangePartySize(`${newPartySize}`)
        }}
      />
      <TextInput
        value={partySize}
        onChangeText={(text) => onChangePartySize(text)}
        keyboardType="number-pad"
        style={{
          flex: 1,
          marginHorizontal: 8,
          height: 64,
          paddingHorizontal: 16,
          width: Platform.OS === 'web' ? 164 : undefined,
        }}
      />
      <QuantityButton
        disabled={Number(partySize || '0') >= maxPartySize}
        icon={faPlus}
        onPress={() => {
          const newPartySize = Number(partySize || '0') + 1
          if (newPartySize > maxPartySize) {
            onChangePartySize(`${maxPartySize}`)
          }
          onChangePartySize(`${newPartySize}`)
        }}
      />
    </View>
  )
}

function validate({
  dateTime,
  adultPartySize,
  childPartySize,
  restaurantBusinessTimes,
  isRestaurantBusinessTimesLoading,
}: {
  dateTime: dayjs.Dayjs
  adultPartySize: string
  childPartySize: string
  restaurantBusinessTimes: RestaurantBusinessTimeModel[] | undefined
  isRestaurantBusinessTimesLoading: boolean
}) {
  let disabled = false
  const errors: string[] = []

  const startTime = toSeconds(dateTime.hour(), dateTime.minute())

  if (isEmpty(adultPartySize) && isEmpty(childPartySize)) {
    disabled = true
    errors.push(t('人数を入力してください'))
  }

  if (!isRestaurantBusinessTimesLoading && restaurantBusinessTimes != null) {
    const businessTimesWithinOpeningHours = restaurantBusinessTimes.filter(
      (restaurantBusinessTime) =>
        startTime >= restaurantBusinessTime.start_time &&
        startTime <= restaurantBusinessTime.end_time
    )

    if (businessTimesWithinOpeningHours.length === 0) {
      errors.push(t('営業時間外です'))
    }
    const businessTimesPastLastOrder = businessTimesWithinOpeningHours.filter(
      (restaurantBusinessTime) => {
        if (restaurantBusinessTime.last_order_time == null) return false
        return startTime > restaurantBusinessTime.last_order_time
      }
    )

    if (businessTimesPastLastOrder.length > 0) {
      errors.push(t('ラストオーダーの時間を超えています'))
    }
  }

  return { disabled, errors }
}

const getDisplayHour = (hour: number) => {
  return hour >= 0 && hour < 5 ? hour + 24 : hour
}

const getActualHour = (hour: number) => {
  return hour >= 24 ? hour - 24 : hour
}

export function PartySize({
  adultPartySize,
  childPartySize,
  setAdultPartySize,
  setChildPartySize,
  isChartView = false,
}: {
  adultPartySize: string
  childPartySize: string
  setAdultPartySize: (value: string) => void
  setChildPartySize: (value: string) => void
  isChartView?: boolean
}) {
  const { width, sm } = useResponsive()

  if (width <= sm) {
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={{ marginRight: 16, width: 64 }}>{t('大人')}:</Text>
          <PartySizeInput
            partySize={adultPartySize}
            onChangePartySize={setAdultPartySize}
          />
        </View>
        <View style={{ marginTop: 16 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 16, width: 64 }}>{t('お子様')}:</Text>
            <PartySizeInput
              partySize={childPartySize}
              onChangePartySize={setChildPartySize}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <View style={{ flexDirection: 'row', marginTop: 24, gap: 8 }}>
        <Text
          style={{
            fontSize: isChartView ? 36 : 28,
            fontWeight: '600',
            color: Colors.black,
          }}
        >
          {Number(adultPartySize) + Number(childPartySize)}
        </Text>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            color: Colors.black,
            marginTop: isChartView ? 20 : 12,
          }}
        >
          {t('人')}
        </Text>
      </View>
      <View style={{ flexDirection: 'row', marginBottom: 20, gap: 8 }}>
        <QuantityButton
          radius={isChartView ? 20 : 24}
          disabled={Number(childPartySize) <= 0}
          icon={faMinus}
          onPress={() => {
            const totalPartySize =
              Number(adultPartySize) + Number(childPartySize)
            const newChildPartySize = Math.max(0, Number(childPartySize) - 1)
            setChildPartySize(newChildPartySize.toString())
            setAdultPartySize((totalPartySize - newChildPartySize).toString())
          }}
        />
        <Text
          style={{
            fontSize: 14,
            color: Colors.black,
            marginTop: isChartView ? 12 : 16,
          }}
        >
          {t('うちお子様')}
        </Text>
        <Text
          style={{
            fontSize: isChartView ? 24 : 20,
            fontWeight: '600',
            color: Colors.black,
            marginTop: isChartView ? 2 : 10,
          }}
        >
          {childPartySize}
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: Colors.black,
            marginTop: isChartView ? 12 : 16,
          }}
        >
          {t('人')}
        </Text>
        <QuantityButton
          radius={isChartView ? 20 : 24}
          disabled={
            Number(childPartySize) >=
            Number(adultPartySize) + Number(childPartySize)
          }
          icon={faPlus}
          onPress={() => {
            const totalPartySize =
              Number(adultPartySize) + Number(childPartySize)
            const newChildPartySize = Math.min(
              totalPartySize,
              Number(childPartySize) + 1
            )
            setChildPartySize(newChildPartySize.toString())
            setAdultPartySize((totalPartySize - newChildPartySize).toString())
          }}
        />
      </View>
      <View
        style={{
          width: isChartView ? '100%' : 248,
          aspectRatio: 3 / 4,
          gap: 4,
        }}
      >
        {[3, 2, 1, 4].map((row) => (
          <View key={row} style={{ flex: 1, flexDirection: 'row', gap: 4 }}>
            {[1, 2, 3].map((col) => {
              const number = (row - 1) * 3 + col
              const displayText =
                number === 10 ? '0' : number === 11 ? '' : number.toString()

              if (number === 12) return null

              return (
                <TouchableOpacity
                  key={col}
                  style={{
                    flex: number === 10 ? 2.1 : 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: Colors.border,
                    borderRadius: 3,
                  }}
                  onPress={() => {
                    if (number < 10) {
                      const total =
                        Number(adultPartySize) + Number(childPartySize)
                      const newAdultPartySize = total * 10 + number
                      setAdultPartySize(newAdultPartySize.toString())
                      // 10は0ボタン
                    } else if (number === 10) {
                      const total =
                        Number(adultPartySize) + Number(childPartySize)
                      const newAdultPartySize = total * 10
                      setAdultPartySize(newAdultPartySize.toString())
                      // 11は削除ボタン
                    } else if (number === 11) {
                      const total =
                        Number(adultPartySize) + Number(childPartySize)
                      const newTotal = Math.floor(total / 10)
                      const newChildPartySize =
                        Number(childPartySize) > newTotal
                          ? 0
                          : Number(childPartySize)
                      setAdultPartySize(
                        (newTotal - newChildPartySize).toString()
                      )
                      setChildPartySize(newChildPartySize.toString())
                    }
                  }}
                >
                  {number === 11 ? (
                    <FontAwesomeIcon
                      icon={faDeleteLeft}
                      size={24}
                      color={Colors.black}
                    />
                  ) : (
                    <Text style={{ fontSize: 22 }}>{displayText}</Text>
                  )}
                </TouchableOpacity>
              )
            })}
          </View>
        ))}
      </View>
    </View>
  )
}

export function DateTimeAndPartySize({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressGoBack,
  onPressNext,
  onPressSkip,
  onLoad,
}: ReservationFormStepProps & { restaurantId: number; onLoad?: () => void }) {
  const { width, sm } = useResponsive()
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [dateTime, setDateTime] = useFormState(
    dayjs(`${state.dateString} ${getFormatTime(state.startTime)}`)
  )
  const [adultPartySize, setAdultPartySize] = useFormState(
    `${state.adultPartySize}`
  )
  const [childPartySize, setChildPartySize] = useFormState(
    `${state.childPartySize}`
  )

  const {
    restaurantBusinessTimes,
    isLoading: isRestaurantBusinessTimesLoading,
  } = useRestaurantBusinessTimesByDate(restaurantId, {
    date: dateTime.format('YYYY-MM-DD'),
  })
  const isRestaurantOpenAt = useCallback(
    (hour: number, min: number) => {
      if (isRestaurantBusinessTimesLoading) {
        return true
      }

      return restaurantBusinessTimes?.some((restaurantBusinessTime) =>
        inRange(
          toSeconds(hour, min),
          restaurantBusinessTime.start_time,
          restaurantBusinessTime.end_time
        )
      )
    },
    [restaurantBusinessTimes, isRestaurantBusinessTimesLoading]
  )

  const { disabled, errors } = useMemo(
    () =>
      validate({
        dateTime,
        adultPartySize,
        childPartySize,
        restaurantBusinessTimes,
        isRestaurantBusinessTimesLoading,
      }),
    [
      adultPartySize,
      childPartySize,
      dateTime,
      restaurantBusinessTimes,
      isRestaurantBusinessTimesLoading,
    ]
  )

  const onCalendarModalPress = useCallback(
    (d: dayjs.Dayjs) => {
      setDateTime((dateTime) =>
        dateTime.year(d.year()).month(d.month()).date(d.date())
      )
    },
    [setDateTime]
  )

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  const handleOnLoad = useEffectEvent(() => {
    onLoad?.()
  })
  useEffect(() => {
    handleOnLoad?.()
  }, [handleOnLoad])

  // 画面を開いた時、デフォルトの時間の位置までスクロールする
  const hourScrollViewRef = useRef<ScrollView>(null)
  const isInitialRender = useRef(true)
  useEffect(() => {
    if (isInitialRender.current && hourScrollViewRef.current) {
      const scrollToIndex = dateTime.hour() - 5
      if (scrollToIndex >= 0) {
        setTimeout(() => {
          hourScrollViewRef.current?.scrollTo({
            y: scrollToIndex * 60,
            animated: false,
          })
        }, 100)
      }
      isInitialRender.current = false
    }
  }, [dateTime])

  const handleOnPress = (
    onPress?: (newParams: Partial<ReservationFormState>) => void
  ) => {
    const dateString = dateTime.format('YYYY-MM-DD')
    const startTime = dateTime.hour() * 3600 + dateTime.minute() * 60

    // 日付や時間を変更した場合、既に選択されていた時刻や席が選択できない状態になる場合があるため、終了時刻および選択している席の情報をリセットする
    const shouldResetSeatInfo =
      dateString === state.dateString && startTime === state.startTime

    const endTime = shouldResetSeatInfo ? state.endTime : undefined
    const selectedSeatIds = shouldResetSeatInfo ? state.selectedSeatIds : []

    if (onPress) {
      onPress({
        dateString,
        startTime,
        endTime,
        selectedSeatIds,
        adultPartySize: Number(adultPartySize || '0'),
        childPartySize: Number(childPartySize || '0'),
      })
    }
  }

  const onPressNextWithParams = () => {
    handleOnPress(onPressNext)
  }

  const onPressSkipWithParams = () => {
    handleOnPress(onPressSkip)
  }

  const onPressNextButton = (
    <ReservationStepperNextButton
      onPressNext={onPressNextWithParams}
      text={t('席を選択する')}
    />
  )

  const onPressSkipButton = (
    <ReservationStepperSkipButton
      onPressSkip={onPressSkipWithParams}
      text={t('席の選択をスキップする')}
    />
  )

  if (width <= sm) {
    return (
      <ReservationStepper
        steps={RESERVATION_STEPPERS}
        currentStepNumber={currentStep == null ? undefined : currentStep}
        skipStepNumbers={skipSteps}
        errors={[]}
        onPressGoBack={onPressGoBack}
        onPressNext={onPressNextWithParams}
        isDisabledNextPress={disabled}
      >
        <ScrollView
          style={{ flex: 1, backgroundColor: 'white' }}
          contentContainerStyle={{
            flexDirection: 'column',
            alignItems: 'center',
            paddingHorizontal: 32,
            paddingVertical: 48,
          }}
        >
          <View style={{ width: '100%' }}>
            <View>
              <DateQuestionTitle />
              <View style={{ marginTop: 24 }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: Colors.field,
                    borderRadius: 8,
                    height: 64,
                    paddingHorizontal: 16,
                    justifyContent: 'center',
                  }}
                  onPress={() => setCalendarVisible(true)}
                >
                  {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                  <Text>{dateTime.format('YYYY年M月DD日(ddd)')}</Text>
                </TouchableOpacity>
                <CalendarModal
                  restaurantId={restaurantId}
                  title={t('日付を選択')}
                  isModalVisible={isCalendarVisible}
                  onClose={() => setCalendarVisible(false)}
                  selectedDate={dateTime}
                  onPress={onCalendarModalPress}
                />
              </View>
            </View>
            <View style={{ marginTop: 40 }}>
              <DateTimeQuestionTitle />
              <View
                style={{
                  marginTop: 24,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SelectDateTimeInput
                  mode="time"
                  dateTime={dateTime}
                  minuteInterval={15}
                  onChangeDateTime={(d) => {
                    setDateTime((dateTime) =>
                      dateTime.hour(d.hour()).minute(d.minute())
                    )
                  }}
                  style={{
                    height: 64,
                    paddingHorizontal: 16,
                    width: '100%',
                  }}
                />
              </View>
            </View>
            <View style={{ marginTop: 40 }}>
              <PartySizeQuestionTitle />
              <View style={{ marginTop: 24 }}>
                <PartySize
                  adultPartySize={adultPartySize}
                  childPartySize={childPartySize}
                  setAdultPartySize={setAdultPartySize}
                  setChildPartySize={setChildPartySize}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </ReservationStepper>
    )
  }

  return (
    <ReservationStepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={onPressNextWithParams}
      onPressNextButton={onPressNextButton}
      onPressSkipButton={onPressSkipButton}
      isDisabledNextPress={disabled}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: 'white' }}
        contentContainerStyle={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingHorizontal: 32,
          paddingVertical: 30,
        }}
      >
        <View
          style={{ flexDirection: 'row', justifyContent: 'center', gap: 40 }}
        >
          <View
            style={{
              width: 310,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DateQuestionTitle />
            <Text
              style={{
                marginTop: 24,
                fontSize: 28,
                fontWeight: '600',
                color: Colors.black,
              }}
            >
              {dateTime.format('YYYY/MM/DD(ddd)')}
            </Text>
            <ScrollView
              style={{
                height: 344,
                marginTop: 16,
                borderWidth: 1,
                borderColor: Colors.border,
                borderRadius: 8,
                width: '100%',
              }}
            >
              {[...Array(14)].map((_, index) => {
                const date = dayjs().add(index, 'day')
                const isSelected = date.isSame(dateTime, 'day')
                const isToday = date.isSame(dayjs(), 'day')
                const isTomorrow = date.isSame(dayjs().add(1, 'day'), 'day')
                const isDayAfterTomorrow = date.isSame(
                  dayjs().add(2, 'day'),
                  'day'
                )
                const isSaturday = date.day() === 6
                const isSunday = date.day() === 0

                let dateColor: string = Colors.black
                if (isSaturday) dateColor = '#1B76E1'
                if (isSunday) dateColor = '#D21028'

                return (
                  <TouchableOpacity
                    key={index}
                    style={{
                      height: 60,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: isSelected
                        ? Colors.accentBg
                        : 'transparent',
                      position: 'relative',
                      borderTopWidth: index > 0 ? 0.5 : 0,
                      borderColor: Colors.border,
                    }}
                    onPress={() =>
                      setDateTime((prevDateTime) =>
                        date
                          .hour(prevDateTime.hour())
                          .minute(prevDateTime.minute())
                      )
                    }
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        color: isSelected ? Colors.accent : dateColor,
                        fontWeight: isSelected ? '600' : '300',
                      }}
                    >
                      {date.format('M/DD(ddd)')}
                    </Text>
                    {(isToday || isTomorrow || isDayAfterTomorrow) && (
                      <View
                        style={{
                          position: 'absolute',
                          right: 32,
                          borderWidth: 1,
                          borderColor: Colors.border,
                          borderRadius: 4,
                          paddingHorizontal: 4,
                          paddingVertical: 0,
                        }}
                      >
                        <Text style={{ fontSize: 16, color: Colors.gray }}>
                          {isToday
                            ? t('今日')
                            : isTomorrow
                              ? t('明日')
                              : t('明後日')}
                        </Text>
                      </View>
                    )}
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
            <View style={{ marginTop: 20, width: '100%' }}>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: Colors.border,
                  borderRadius: 8,
                  width: '100%',
                  height: 48,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 8,
                }}
                onPress={() => setCalendarVisible(true)}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size={16}
                  color={Colors.black}
                />
                <Text style={{ fontSize: 16, color: Colors.black }}>
                  カレンダーから選ぶ
                </Text>
              </TouchableOpacity>
              <CalendarModal
                restaurantId={restaurantId}
                title={t('日付を選択')}
                isModalVisible={isCalendarVisible}
                onClose={() => setCalendarVisible(false)}
                selectedDate={dateTime}
                onPress={onCalendarModalPress}
              />
            </View>
          </View>
          <View
            style={{
              width: 310,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DateTimeQuestionTitle />
            <Text
              style={{
                marginTop: 24,
                fontSize: 28,
                fontWeight: '600',
                color: Colors.black,
              }}
            >
              {getDisplayHour(dateTime.hour()).toString().padStart(2, '0')}:
              {dateTime.minute().toString().padStart(2, '0')}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 16,
                gap: 30,
                width: '100%',
              }}
            >
              <ScrollView
                ref={hourScrollViewRef}
                style={{
                  width: 140,
                  height: 412,
                  borderWidth: 1,
                  borderColor: Colors.border,
                  borderRadius: 8,
                }}
              >
                {Array.from({ length: 24 }, (_, i) => i + 5).map(
                  (hour, index) => {
                    const displayHour = getDisplayHour(hour % 24)
                    const isSelected =
                      displayHour === getDisplayHour(dateTime.hour())
                    const isOpen = [0, 15, 30, 45].some((minute) =>
                      isRestaurantOpenAt(hour, minute)
                    )
                    return (
                      <TouchableOpacity
                        key={`hour-${hour}`}
                        style={{
                          height: 60,
                          backgroundColor: isSelected
                            ? Colors.accentBg
                            : isOpen
                              ? 'transparent'
                              : Colors.bgBlack,
                          borderTopWidth: index > 0 ? 0.5 : 0,
                          borderColor: Colors.border,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() =>
                          setDateTime((prevDateTime) =>
                            prevDateTime.hour(getActualHour(hour % 24))
                          )
                        }
                      >
                        <Text
                          style={{
                            textAlign: 'center',
                            fontSize: 22,
                            color: isSelected ? Colors.accent : Colors.black,
                            fontWeight: isSelected ? '600' : '300',
                          }}
                        >
                          {displayHour.toString().padStart(2, '0')}
                        </Text>
                      </TouchableOpacity>
                    )
                  }
                )}
              </ScrollView>
              <View
                style={{
                  width: 140,
                  height: 243,
                  borderWidth: 1,
                  borderColor: Colors.border,
                  borderRadius: 8,
                }}
              >
                {[0, 15, 30, 45].map((minute, index) => {
                  const isOpen = isRestaurantOpenAt(
                    getDisplayHour(dateTime.hour()),
                    minute
                  )
                  return (
                    <TouchableOpacity
                      key={`minute-${minute}`}
                      style={{
                        height: 60,
                        backgroundColor:
                          dateTime.minute() === minute
                            ? Colors.accentBg
                            : isOpen
                              ? 'transparent'
                              : Colors.bgBlack,
                        borderTopWidth: index > 0 ? 0.5 : 0,
                        borderColor: Colors.border,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onPress={() =>
                        setDateTime((prevDateTime) =>
                          prevDateTime.minute(minute)
                        )
                      }
                    >
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: 22,
                          color:
                            dateTime.minute() === minute
                              ? Colors.accent
                              : Colors.black,
                          fontWeight:
                            dateTime.minute() === minute ? '600' : '300',
                        }}
                      >
                        {minute.toString().padStart(2, '0')}
                      </Text>
                    </TouchableOpacity>
                  )
                })}
              </View>
            </View>
          </View>
          <View
            style={{
              width: 310,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <PartySizeQuestionTitle />
            <PartySize
              adultPartySize={adultPartySize}
              childPartySize={childPartySize}
              setAdultPartySize={setAdultPartySize}
              setChildPartySize={setChildPartySize}
            />
          </View>
        </View>
      </ScrollView>
    </ReservationStepper>
  )
}
