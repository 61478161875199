import React, { useCallback, useMemo, useState } from 'react'

import { View, FlatList, Image, Linking, Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import Loading from '../../components/Shared/Loading'
import { Customer } from '@hello-ai/for_r_app/src/components/Customers/Customer'

import { useCustomerSearch } from '@hello-ai/for_r_app/src/models/Customer'
import { CustomerSearchForm, SearchHandler } from './CustomerSearchForm'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { useRestaurantAttributes } from '@hello-ai/for_r_app/src/models/CustomerAttributes'
import {
  isAvailableShowVisitCount,
  useRestaurant,
} from '@hello-ai/for_r_app/src/models/Restaurant'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'

export function Customers({
  restaurantId,
  customerId,
}: {
  restaurantId: number
  customerId?: string
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null ||
    restaurant.reservation_book_plan_type === 'free' ||
    restaurant.reservation_book_plan_type === 'entry'
  // const [isSort, setIsSort] = useState(0)

  const { data } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const attributes = useMemo(
    () => data?.filter((e) => e.display_in_list) ?? [],
    [data]
  )

  const [currentPage, setCurrentPage] = useState(1)

  const {
    setSearchParams,
    mutate: mutateSearch,
    customers,
    headerData,
  } = useCustomerSearch(restaurantId, currentPage)

  const searchCustomer = useCallback<SearchHandler>(
    ({
      phoneNumber,
      dialCode,
      lastName,
      lastNameKana,
      firstName,
      firstNameKana,
    }) => {
      setSearchParams({
        phoneNumber,
        dialCode,
        reservationLastName: lastNameKana,
        reservationFirstName: firstNameKana,
        lastName,
        firstName,
      })
      setCurrentPage(1)
    },
    [setSearchParams, setCurrentPage]
  )

  const handleCustomerAdded = useCallback(() => {
    mutateSearch()
    displayToastSuccess(t('新規顧客を登録しました'))
  }, [mutateSearch])

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: width < sm ? 24 : 32,
      }}
    >
      {isFreePlan && (
        <View
          style={{
            flexDirection: 'row',
            padding: 16,
            backgroundColor: Colors.white,
            borderRadius: 8,
            borderColor: Colors.primary,
            borderWidth: 1,
            marginBottom: 40,
            gap: 12,
            alignItems: 'center',
          }}
        >
          <Image
            style={{ height: 16, width: 16, marginRight: 4 }}
            source={require('../../assets/images/plan-icon.png')}
          />
          <Text style={{ flex: 1, color: Colors.black }}>
            {restaurant?.reservation_book_plan_type === 'entry'
              ? t(
                  'リクエスト予約の場合、お客様属性、予約回数の表示はご利用はできません。ご利用希望の方はお問い合わせください'
                )
              : t(
                  'お客様属性、予約回数の表示はスタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
                )}
          </Text>
          <TouchableOpacity
            style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
            onPress={() =>
              Linking.openURL('https://autoreserve.com/for_restaurants/contact')
            }
          >
            <Text style={{ color: Colors.primary }}>{t('お問い合わせへ')}</Text>
            <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
          </TouchableOpacity>
        </View>
      )}
      <CustomerSearchForm
        restaurantId={restaurantId}
        onSearch={searchCustomer}
        onCustomerAdded={handleCustomerAdded}
      />

      {customers == null ? (
        <View style={{ paddingVertical: 24 }}>
          <Loading />
        </View>
      ) : (
        <View
          style={[
            {
              borderRadius: 8,
              shadowRadius: 4,
              elevation: 4,
              shadowColor: '#000',
              shadowOpacity: 0.1,
              shadowOffset: { width: 0, height: 2 },
              backgroundColor: 'white',
              padding: 0,
              marginTop: width >= sm ? 16 : 24,
            },
          ]}
        >
          <FlatList
            data={
              customerId != null
                ? customers.filter((i) => i.id !== customerId)
                : customers
            }
            keyExtractor={(item) => item.id}
            renderItem={({ item: customer }) => {
              return (
                <View
                  key={customer.id}
                  style={{
                    [width >= sm ? 'marginHorizontal' : 'paddingHorizontal']:
                      width >= sm ? 24 : 16,
                    borderBottomWidth: 0.5,
                    borderBottomColor: Colors.border,
                  }}
                >
                  <Customer
                    customer={customer}
                    restaurant={restaurant}
                    onPress={() => {
                      if (Platform.OS === 'web') {
                        navigate(`./${customer.id}`)
                      } else {
                        navigation.navigate('Customer', {
                          customerId: customer.id,
                        })
                      }
                    }}
                    attributes={attributes}
                  />
                </View>
              )
            }}
            ListHeaderComponent={
              <View
                style={{
                  flexDirection: 'row',
                  paddingVertical: 16,
                  [width >= sm ? 'marginHorizontal' : 'paddingHorizontal']:
                    width >= sm ? 24 : 16,
                  borderBottomWidth: 0.5,
                  borderColor: Colors.border,
                }}
              >
                <Text style={{ flex: 1, fontSize: 14, fontWeight: '600' }}>
                  {t('名前')}
                </Text>
                {isAvailableShowVisitCount(restaurant) && (
                  <Text
                    style={{
                      width: width >= sm ? 142 : 64,
                      fontSize: 14,
                      fontWeight: '600',
                    }}
                  >
                    {t('予約回数')}
                  </Text>
                )}
              </View>
            }
            ListEmptyComponent={
              <Text
                style={{
                  paddingVertical: 40,
                  paddingHorizontal: 16,
                  fontSize: 18,
                  lineHeight: 27,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する顧客はいません')}
              </Text>
            }
            ListFooterComponent={
              <View
                style={{
                  paddingVertical: 16,
                }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPage={headerData?.totalPages ?? 0}
                  setPage={(page) => setCurrentPage(page)}
                />
              </View>
            }
          />
        </View>
      )}
    </KeyboardAwareScrollView>
  )
}
